<template>
  <div>
    <!-- 开始 -->
    <div class="mstail">
      <!-- 手机页面 -->
      <div class="phone" v-if="webson === 7">
        <!-- 轮播图 -->
        <div>
          <el-carousel :interval="5000" arrow="always" height="200px">
            <el-carousel-item v-for="item in lunboone" :key="item">
              <img :src="item.src" alt="" style="width: 100%; height: 100%" />
            </el-carousel-item>
          </el-carousel>
        </div>
        <!-- 内容 -->
        <div
          class="consets"
          :style="{ backgroundImage: 'url(' + sonback + ') ' }"
        >
          <div class="conset">
            <div
              class="imagetitle"
              v-for="item in sonimage"
              :key="item.id"
              @click="sonwebhtml(item)"
            >
              <div class="image">
                <img :src="item.image" alt="" />
              </div>
              <div class="title">{{ item.title }}</div>
            </div>
            <div class="imagetitle" @click="addliveson">
              <div class="image">
                <img src="../../images/phone1.png" alt="" />
              </div>
              <div class="title">添加</div>
            </div>
          </div>
        </div>
        <!-- 弹起 -->
        <div>
          <el-dialog
            v-model="centerDialogVisible"
            title="Notice"
            width="30%"
            destroy-on-close
            center
          >
            <div class="spandialog" v-if="upwebliveimage">
              <el-button type="primary" @click="pattern(0)">图文编辑</el-button>
              <el-button type="primary" @click="pattern(1)"
                >报名表编辑</el-button
              >
              <el-button type="primary" @click="pattern(2)">外部链接</el-button>
            </div>
            <!-- 上传头像 -->
            <div class="imagetoplist" v-else>
              <div class="uploadimage">
                <el-upload
                  :http-request="coverposterupload"
                  class="avatar-uploader"
                  :show-file-list="false"
                  :on-success="coverhandleAvatarSuccess"
                  :before-upload="coverbeforeAvatarUpload"
                  :on-change="coverlisttolive"
                  :auto-upload="false"
                >
                  <img v-if="imageupload" :src="imageupload" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </div>
              <div class="titleupload">
                <input type="text" v-model="inputtitle" />
              </div>
              <div class="uploadbutton">
                <el-button type="primary" @click="uploadportrait"
                  >确定</el-button
                >
              </div>
            </div>
          </el-dialog>
        </div>
      </div>
      <!-- 手机子页面 -->
      <div class="phone" v-else-if="webson === 0">
        <div class="quilweb">
          <div class="inputson">{{ sonname.title }}</div>
          <div class="tohome" @click="tohome">返回</div>
        </div>
        <div v-html="quilqpplist"></div>
      </div>
      <div class="phone" v-else-if="webson === 1">
        <div class="quilweb">
          <div class="inputson">{{ sonname.title }}</div>
          <div class="tohome" @click="tohome">返回</div>
        </div>
        <div></div>
      </div>
      <div class="phone" v-else-if="webson === 2">
        <div class="quilweb">
          <div class="inputson">{{ sonname.title }}</div>
          <div class="tohome" @click="tohome">返回</div>
        </div>
        <div>
          <a :href="ahearf">{{ ahearf }}</a>
        </div>
      </div>
      <!-- 编辑页面 -->
      <div class="editlive">
        <!-- 主页面 -->
        <div class="mainpage" v-if="webson === 7">
          <el-form
            :label-position="labelPosition"
            label-width="80px"
            :model="formLabelAlign"
          >
            <!-- 轮播图 -->
            <el-form-item label="轮播图">
              <el-upload
                :http-request="lunbouploadone"
                :show-file-list="false"
                :on-success="lunbouploadoneAvatarSuccess"
                :before-upload="beforlunbouploadone"
                :on-change="lunbouploadonelisttolive"
                :auto-upload="false"
              >
                <i class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <div class="lunbo">
                <div
                  class="listtoavatar"
                  v-for="item in lunboone"
                  :key="item.id"
                >
                  <i
                    class="el-icon-delete lunbofonts"
                    @click="deletfontlunbo(item.id)"
                  ></i>
                  <img :src="item.src" class="avatar" />
                </div>
              </div>
            </el-form-item>
            <el-form-item label="上传图片">
              <el-upload
                :http-request="posterupload"
                class="avatar-uploader"
                :show-file-list="false"
                :on-success="posterhandleAvatarSuccess"
                :before-upload="posterbeforeAvatarUpload"
                :on-change="posterlisttolive"
                :auto-upload="false"
              >
                <img
                  v-if="uploadimagelisttodo"
                  :src="uploadimagelisttodo"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="背景图">
              <el-button type="primary" size="small" @click="background_map">
                点击使用
              </el-button>
            </el-form-item>
            <el-form-item label="活动形式">
              <div class="conset">
                <div class="imagetitle" v-for="item in sonimage" :key="item.id">
                  <div class="image">
                    <img :src="item.image" alt="" />
                  </div>
                  <div class="title">
                    <input type="text" v-model="item.title" />
                  </div>
                  <div style="text-align: center">
                    <el-button
                      type="primary"
                      size="small"
                      @click="sonlisttodo(item)"
                    >
                      使用图片
                    </el-button>
                    <el-button
                      type="primary"
                      size="small"
                      @click="deletimage(item)"
                    >
                      删除
                    </el-button>
                  </div>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div class="mainpage" v-else-if="webson === 0">
          <quil @childFn="parentFn"></quil>
        </div>
        <div class="mainpage" v-else-if="webson === 1">
          <el-table :data="tableData" border style="width: 100%">
            <el-table-column prop="id" label="序号" width="180">
            </el-table-column>
            <el-table-column prop="title" label="姓名" width="180">
            </el-table-column>
            <el-table-column label="地址">
              <template v-slot="scope">
                <el-button
                  type="text"
                  v-if="baomingid == scope.row.id"
                  @click="livedeletbaoming(scope.row)"
                  >取消报名表</el-button
                >
                <el-button v-else type="text" @click="aleatbaoming(scope.row)"
                  >选择</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="mainpage" v-else-if="webson === 2">
          <div>
            <span>请输入外部链接:</span>
            <input type="text" v-model="ahearf" />
            <el-button type="text" @click="externalhear">确定</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="subimtwebapp">
      <el-button type="primary" @click="subimtwebapp">提交内容</el-button>
    </div>
  </div>
</template>
<script>
import quil from './quil/index.vue'
import { baoming, baominglive, detachbaomingbiao, setmicrosite, getmicrosite } from '../../api/index'
import qs from 'qs';
export default {
  data () {
    return {
      // 直播id
      livelistid: '',
      images: [
        'https://img.yzcdn.cn/vant/apple-1.jpg',
        'https://img.yzcdn.cn/vant/apple-2.jpg',
      ],
      centerDialogVisible: false,
      // 轮播图
      // 轮播上传1.名字
      lunboonenamelist: '',
      // 图标
      lunbooneimgfile: '',
      // 后缀
      lunboonetype: '',
      // 轮播图
      lunboone: [{ id: 1, src: 'https://upfile2.asqql.com/upfile/hdimg/wmtp/wmtp/2015-12/30/9835VicmIhquvD.jpg' },
      { id: 2, src: 'https://upfile2.asqql.com/upfile/hdimg/wmtp/wmtp/2015-12/30/9835VicmIhquvD.jpg' }

      ],
      lunbolist: { id: 1, src: '' },
      // 头像
      // 头像粘片
      covertype: '',
      // 头像名字
      covernamelist: '',
      // 头像内容
      coverimgfile: '',
      // 粘片
      imageupload: '',
      // 显示
      sonimagelist: { id: 0, title: '', image: '', son: [], webtitle: '', baoid: '' },
      sonimage: [],
      // 弹窗内容显示
      upwebliveimage: true,
      // 输入框
      inputtitle: '',
      // 显示那个页面shou几
      webson: 7,
      // 进入子页面名称
      sonname: [],
      // 子页面文本编辑器
      quilqpplist: '',
      // 报名表
      tableData: [],
      // 报名表拍id
      baomingid: '',
      // 外部链接
      ahearf: '',
      // 后台的所有数据
      mader: [{
        lunbo: '', beijing: '', son: ''
      }],
      // 上传的图片
      postertype: '',
      posternamelist: '',
      posterimgfile: '',
      // 使用的照片
      uploadimagelisttodo: '',
      // 背景
      sonback: ''
    }
  },
  methods: {
    // 取回数据
    fathdata () {
      var userid = JSON.parse(localStorage.getItem("userid"));
      let app = qs.stringify({
        txliveid: this.livelistid,
        userid: userid,
        cridential: '1',
      })
      getmicrosite(app).then(res => {
        console.log(res)
        if (res.content == '[]') {
          console.log('1')
        } else {
          let el = eval("(" + res.content + ")");
          console.log(el)
          this.mader = el
          this.lunboone = this.mader[0].lunbo
          this.sonback = this.mader[0].beijing
          this.sonimage = this.mader[0].son
          console.log(this.mader)
          console.log(this.lunboone, this.sonimage, this.sonback)
        }
      })
    },
    // 轮播
    beforlunbouploadone (file) {
      console.log(file)
      const isJPG = file.type === 'image/jpeg';
      const isPng = file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isJPG && !isPng) {
        this.$message.error('上传头像图片只能是 JPG 和png 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    // 轮播图1进行
    lunbouploadonelisttolive (file) {
      console.log(file.raw.type)
      const isJPG = file.raw.type === 'image/jpeg';
      const isPng = file.raw.type === 'image/png'
      const isLt2M = file.raw.size / 1024 / 1024 < 2;
      console.log(URL.createObjectURL(file.raw))
      if (!isJPG && !isPng) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      } else if (!isLt2M) {
        console.log(isJPG)
        this.$message.error('上传头像图片大小不能超过 2MB!');
      } else {
        this.lunboonetype = file.raw.type
        this.lunboonenamelist = file.name
        // console.log(111)
        this.lunbooneimgfile = file.raw
        this.lunbouploadoneupload()
        // this.lunboone = URL.createObjectURL(file.raw)
      }
    },
    // 轮播1上传
    lunbouploadoneupload () {
      if (this.lunbooneimgfile != '' && this.lunbooneimgfile != undefined) {
        console.log(this.lunbooneimgfile)
        var COS = require('cos-js-sdk-v5');
        var cos = new COS({
          SecretId: 'AKIDUtGahY9UYLr52i7F6gz1mGTTDi4KOBiy',
          SecretKey: '1YtymIZZJmytXuzoMddMozsUpthEVVDe'
        });
        this.cosdata = cos
        let data = new Date().getTime()
        let random = Math.floor(Math.random() * 100);
        let image = this.lunbooneimgfile
        let name = this.lunboonenamelist
        let namelist = data + '-' + random + '-' + name
        console.log(namelist)
        this.cosdata.putObject({
          Bucket: 'txcos-1306887409', /* 必须 */
          Region: 'ap-beijing', /* 存储桶所在地域，需要后端提供 */
          Key: namelist, /* 必须 */
          StorageClass: 'STANDARD',
          Body: image, // 上传文件对象
          // FilePath: 'tx4s',
          onProgress: (progressData) => {
            console.log(JSON.stringify(progressData));
            // console.log(progressData)
          }
        }, (err, data) => {
          console.log(err || data);
          console.log(data)
          this.lunbolist.src = 'https://' + data.Location
          let app = { id: this.lunbolist.id, src: this.lunbolist.src }
          this.lunboone.push(app)
          for (let i = 0; i < this.lunboone.length; i++) {
            this.lunboone[i].id = i
          }
          console.log(this.lunboone)
        });
      } else {
        console.log(123)
      }
    },
    // 轮播图删除
    deletfontlunbo (e) {
      // console.log(e)
      this.lunboone.splice(e, 1)
      for (let i = 0; i < this.lunboone.length; i++) {
        this.lunboone[i].id = i
      }
    },
    // 添加子页面
    addliveson () {
      console.log('点击')
      this.centerDialogVisible = true
      this.upwebliveimage = true
    },
    // 选择模式
    pattern (e) {
      this.sonimagelist.webtitle = e
      if (this.sonimagelist.webtitle === 1) {
        this.sonimagelist.baoid = this.baomingid
      } else {
        this.sonimagelist.baoid = ''
      }
      // console.log(e)
      this.upwebliveimage = false
    },
    // 头像上传
    // 直播封面
    coverbeforeAvatarUpload (file) {
      console.log(file)
      const isJPG = file.type === 'image/jpeg';
      const isPng = file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isJPG && !isPng) {
        this.$message.error('上传头像图片只能是 JPG 和png 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    // // 进行中
    coverlisttolive (file) {
      console.log(file.raw.type)
      const isJPG = file.raw.type === 'image/jpeg';
      const isPng = file.raw.type === 'image/png'
      const isLt2M = file.raw.size / 1024 / 1024 < 2;
      console.log(URL.createObjectURL(file.raw))
      if (!isJPG && !isPng) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      } else if (!isLt2M) {
        console.log(isJPG)
        this.$message.error('上传头像图片大小不能超过 2MB!');
      } else {
        this.covertype = file.raw.type
        this.covernamelist = file.name
        // console.log(111)
        this.coverimgfile = file.raw
        this.imageupload = URL.createObjectURL(file.raw)
        this.coverupload()
      }
    },
    coverupload () {
      if (this.coverimgfile != '' && this.coverimgfile != undefined) {
        console.log(this.coverimgfile)
        var COS = require('cos-js-sdk-v5');
        var cos = new COS({
          SecretId: 'AKIDUtGahY9UYLr52i7F6gz1mGTTDi4KOBiy',
          SecretKey: '1YtymIZZJmytXuzoMddMozsUpthEVVDe'
        });
        this.cosdata = cos
        let data = new Date().getTime()
        let random = Math.floor(Math.random() * 100);
        let image = this.coverimgfile
        let name = this.covernamelist
        let namelist = data + '-' + random + '-' + name
        console.log(namelist)
        this.cosdata.putObject({
          Bucket: 'txcos-1306887409', /* 必须 */
          Region: 'ap-beijing', /* 存储桶所在地域，需要后端提供 */
          Key: namelist, /* 必须 */
          StorageClass: 'STANDARD',
          Body: image, // 上传文件对象
          // FilePath: 'tx4s',
          onProgress: (progressData) => {
            console.log(JSON.stringify(progressData));
            // console.log(progressData)
          }
        }, (err, data) => {
          console.log(err || data);
          console.log(data)
          // if (data.statusCode == 200) {
          //   console.log(123)
          //   if (this.covertype == 'image/jpeg') {
          //     console.log('jpg')
          //     this.covertype = 'jpg'
          //     let image = data.Location + this.covertype

          this.imageupload = 'https://' + data.Location
          this.coverimgfile = ''
          console.log(this.imageupload)
        });
      } else {
        console.log(123)
      }
    },
    // 确定
    uploadportrait () {
      if (this.inputtitle != '' && this.imageupload != '') {
        // console.log('1')
        let app = { id: this.sonimagelist.id, title: this.inputtitle, image: this.imageupload, son: this.sonimagelist.son, webtitle: this.sonimagelist.webtitle, baoid: this.sonimagelist.baoid }
        this.sonimage.push(app)
        for (var i = 0; i < this.sonimage.length; i++) {
          this.sonimage[i].id = i
          console.log(i)
        }
        this.inputtitle = ''
        this.imageupload = ''
        console.log(this.sonimage)
      }
    },
    // 进入那个子页面
    sonwebhtml (e) {
      console.log(e)
      this.webson = e.webtitle
      this.sonname = e
      if (e.webtitle === 1) {
        baoming().then(res => {
          // console.log(res)
          this.tableData = res.baomingbiaolist
        })
      } else if (e.webtitle === 0) {
        this.quilqpplist = e.son
      } else if (e.webtitle === 2) {
        this.ahearf = e.son
      }
      console.log(e.title)
    },
    // 报名表选择
    aleatbaoming (e) {
      this.$confirm('此操作将直播与报名表绑定, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log(this.livelistid, e.id)
        let app = qs.stringify({
          baomingbiaoid: e.id,
          video_id: this.livelistid
        })
        baominglive(app).then(res => {
          console.log(res)
          if (res.code === 9999) {
            this.$message({
              type: 'success',
              message: '绑定成功!'
            });
            this.baomingid = e.id
            let i = this.sonname.id
            this.sonimage[i].baoid = e.id
            // this.$emit('childFn', '1')
          } else {
            alert('失败')
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消绑定'
        });
      });
      console.log(e.id)
      console.log(e)
    },
    // 取消
    livedeletbaoming (e) {
      console.log(e)
      this.$confirm('此操作将取消直播与报名表绑定, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let app = qs.stringify({
          video_id: this.livelistid
        })
        detachbaomingbiao(app).then(res => {
          console.log(res)
          if (res.code === 9999) {
            this.$message({
              type: 'success',
              message: '取消成功!'
            });
            this.baomingid = 0
            let i = this.sonname.id
            this.sonimage[i].baoid = 0
            // this.$emit('childFn', '1')
          } else {
            alert('失败')
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消解除绑定'
        });
      });
    },
    // 0tuwen 编辑
    parentFn (e) {
      let i = this.sonname.id
      console.log(i)
      this.sonimage[i].son = e
      console.log(this.sonimage)
      this.quilqpplist = e
    },
    //返回主页面
    tohome () {
      this.webson = 7
    },
    // 外部链接
    externalhear () {
      if (this.ahearf != '') {
        console.log(this.sonname.id)
        let i = this.sonname.id
        console.log(i)
        this.sonimage[i].son = this.ahearf
        // this.ahearf = ''
        console.log(this.sonimage)
      } else {
        alert('请输入链接')
      }
    },
    // 上传图片
    posterbeforeAvatarUpload (file) {
      console.log(file)
      const isJPG = file.type === 'image/jpeg';
      const isPng = file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isJPG && !isPng) {
        this.$message.error('上传头像图片只能是 JPG 和png 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    // // 进行中
    posterlisttolive (file) {
      console.log(file.raw.type)
      const isJPG = file.raw.type === 'image/jpeg';
      const isPng = file.type === 'image/png'
      const isLt2M = file.raw.size / 1024 / 1024 < 2;
      console.log(URL.createObjectURL(file.raw))
      if (!isJPG && !isPng) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      } else if (!isLt2M) {
        console.log(isJPG)
        this.$message.error('上传头像图片大小不能超过 2MB!');
      } else {
        this.postertype = file.raw.type
        this.posternamelist = file.name
        // console.log(111)
        this.posterimgfile = file.raw
        this.posterupload()
      }
    },
    // 上传
    posterupload () {
      if (this.posterimgfile != '' && this.posterimgfile != undefined) {
        console.log(this.posterimgfile)
        var COS = require('cos-js-sdk-v5');
        var cos = new COS({
          SecretId: 'AKIDUtGahY9UYLr52i7F6gz1mGTTDi4KOBiy',
          SecretKey: '1YtymIZZJmytXuzoMddMozsUpthEVVDe'
        });
        this.cosdata = cos
        let data = new Date().getTime()
        let random = Math.floor(Math.random() * 100);
        let image = this.posterimgfile
        let name = this.posternamelist
        let namelist = data + '-' + random + '-' + name
        console.log(namelist)
        this.cosdata.putObject({
          Bucket: 'txcos-1306887409', /* 必须 */
          Region: 'ap-beijing', /* 存储桶所在地域，需要后端提供 */
          Key: namelist, /* 必须 */
          StorageClass: 'STANDARD',
          Body: image, // 上传文件对象
          // FilePath: 'tx4s',
          onProgress: (progressData) => {
            console.log(JSON.stringify(progressData));
          }
        }, (err, data) => {
          console.log(data)
          this.uploadimagelisttodo = 'https://' + data.Location
          this.posterimgfile = ''
        });
      } else {
        console.log('第三个')
      }
    },
    // 点击背景图使用
    background_map () {
      if (this.uploadimagelisttodo != '') {
        this.sonback = this.uploadimagelisttodo
      }
    },
    // 更换子头像
    sonlisttodo (e) {
      if (this.uploadimagelisttodo != '') {
        e.image = this.uploadimagelisttodo
      }
    },
    // 删除子数据
    deletimage (e) {
      console.log(e)
      this.sonimage.splice(e, 1)
      for (let i = 0; i < this.sonimage.length; i++) {
        this.lunboone[i].id = i
      }
    },
    // 确定上传
    subimtwebapp () {
      console.log(this.lunboone, this.sonimage, this.sonback)
      this.mader[0].lunbo = this.lunboone
      this.mader[0].beijing = this.sonback
      this.mader[0].son = this.sonimage
      var userid = JSON.parse(localStorage.getItem("userid"));
      let webapp = JSON.stringify(this.mader)
      let app = qs.stringify({
        txliveid: this.livelistid,
        userid: userid,
        cridential: '01',
        content: webapp
      })
      setmicrosite(app).then(res => {
        console.log(res)
        if (res.code == '0') {
          this.$message({
            type: 'success',
            message: '提交成功!'
          });
        }
      })
    }
  },
  components: {
    quil
  },
  created () {
    let res = this.$route.query
    console.log(res)
    this.baomingid = res.baomingid
    this.livelistid = res.id
    this.fathdata()
  }
}
</script>
<style scoped>
/* 电脑页面 */
.mstail {
  display: flex;
}
/* 手机页面 */
.phone {
  width: 390px;
  height: 584px;

  border: 1px solid red;
  box-sizing: content-box;
}
/* 轮播图 */
/* 默认配置 */
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 100px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
/* 手机内容 */
.consets {
  height: 384px;
  box-sizing: content-box;
  padding: 0 10px;
  background: #ccc;
  background-size: 100% 100%;
}
.conset {
  display: flex;
  flex-wrap: wrap;
}
.imagetitle {
  height: 150px;
  padding: 5px;
  width: 30%;
  text-align: center;
  /* background: red; */
}
.image {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  /* border: 1px solid red; */
}
.image img {
  width: 100%;
  height: 100%;
  border-radius: 40px;
}
/* 显示 */
.spandialog {
  text-align: center;
}
/* /bianji */
.editlive {
  width: 700px;
  margin: 0 10px;
  height: 584px;
  overflow: scroll;
  border: 1px solid red;
}
/* 轮播图 */
.lunbo {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.listtoavatar {
  margin: 10px;
  width: 160px;
  height: 90px;
  position: relative;
}
.listtoavatar .el-upload--text {
  width: 100% !important;
  height: 100% !important;
}
.lunbofonts {
  position: absolute;
  top: 10px;
  right: 10px;
  color: red;
}
/* 上传头像 */
.imagetoplist {
  margin: 10px auto;
  height: 200px;
  width: 200px;
}
.uploadimage {
  width: 80px;
  height: 80px;
  /* border: 1px solid red; */
  margin: 0 auto;
}
.titleupload {
  width: 150px;
  margin: 10px auto;
  text-align: center;
}
.titleupload input {
  width: 80px;
}
.uploadbutton {
  text-align: center;
}
/* 上传头像显示 */
.avatar {
  width: 100%;
  height: 100%;
  border-radius: 40px;
}
.uploadimage /deep/.el-upload--text {
  width: 100%;
  height: 80px;
}
/* 手机图文编辑器 */
.quilweb {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 14px;
  height: 35px;
  background: #aeeeee;
  line-height: 35px;
  padding: 0 5px;
  font-weight: 600;
}
/* 提交 */
.subimtwebapp {
  text-align: center;
  margin-top: 5px;
}
</style>
