<template>
  <div>
    <!-- 页面动态化 -->
    <div>
      <!-- 名称 -->
      <el-tag
        v-for="(item, index) in listweb"
        :key="index"
        @click="Submit(item.class)"
        >{{ item.title }}</el-tag
      >
    </div>
  </div>
</template>
<script>
import qs from 'qs';
import { wscontrolcommand } from '../../../api/index'
export default {
  data () {
    return {
      listweb: [
        { id: 0, title: "弹跳", class: "animate__bounce" },
        { id: 1, title: "闪光", class: "animate__flash" },
        { id: 2, title: "脉搏", class: "animate__pulse" },
        { id: 3, title: "胶带撞", class: "animate__rubberBand" },
        { id: 4, title: "左右", class: "animate__shakeX" },
        { id: 5, title: "上下", class: "animate__shakeY" },
        { id: 6, title: "摇头", class: "animate__headShake" },
        { id: 7, title: "秋千", class: "animate__swing" },
        { id: 8, title: "塔达", class: "animate__tada" },
        { id: 9, title: "摆动", class: "animate__wobble" },
        { id: 10, title: "果冻", class: "animate__jello" },
        { id: 11, title: "心跳", class: "animate__heartBeat" },
        { id: 12, title: "回退", class: "animate__backInDown" },
        { id: 13, title: "背向左", class: "animate__backInLeft" },
        { id: 14, title: "后向右", class: "animate__backInRight" },
        { id: 15, title: "回溯", class: "animate__backInUp" },
        { id: 16, title: "左", class: "animate__backOutLeft" },
        { id: 17, title: "右", class: "animate__backOutRight" },
      ],
      // id
      liveid: ""
    }
  },
  props: ['adimationlive'],
  methods: {
    Submit (e) {
      console.log(e)
      let app = qs.stringify({
        command: e,
        txliveid: this.liveid,
      });
      wscontrolcommand(app).then(res => {
        console.log(res);

      })
    }
  },
  created () {
    this.liveid = this.adimationlive
    console.log(this.adimationlive)
  }
}
</script>
