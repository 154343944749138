<template>
  <div>
    <div class="yizhiheome" >
      <!-- 选择 -->
      <div class="xuanze">
        <!-- <div class="sonlist" v-for="(item,index) in listyizhiyun" :key="index" @click="xuanze(index)">
          <span v-if="index==listyizhinumber" class="sonlists">{{ item.title }}</span>
          <span v-else>{{ item.title }}</span>
        </div> -->
        <!-- <div class="sonlists">全网医学会议</div> -->
      </div>
      <!-- 添加 -->
      <div class="weblistadd">
        <div class="addarticle" @click="toaddimage">添加文章</div>
        <div class="addarticle" @click="advertisement">全局广告</div>
        <div class="addarticle" @click="gotoselected">设置精选</div>
        <div class="addarticle" @click="Carousel">轮播图更改</div>
      </div>
    </div>
     <!-- 显示文章 -->
     <div class="yizhitodayarcter" :style="{height:`${faterheight}px`}">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column fixed prop="id" label="序号" width="120" />
        <el-table-column prop="title" label="文章主题" width="130" />
        <el-table-column label="封面" width="160">
          <template #default="scope">
            <img class="imsges" :src="scope.row.poster_url" alt="" />
          </template>
        </el-table-column>
        <el-table-column prop="intro" label="简介" width="120" />
        <el-table-column  label="一级分类" width="200" >
          <template #default="scope">
            <span v-if="scope.row.cate_id=='0'">全部</span>
            <span v-if="scope.row.cate_id=='1'">医疗会议</span>
            <span v-if="scope.row.cate_id=='2'">医疗直播</span>
            <span v-if="scope.row.cate_id=='3'">医疗视频</span>
          </template>
        </el-table-column>
        <el-table-column prop="author_id" label="作者id" width="120" />
        <el-table-column prop="sub_cate_id" label="报名表id" width="120" />
        <el-table-column prop="author_name" label="作者名字" width="120" />
        <el-table-column prop="teacher_id" label="讲者id" width="120" />
        <el-table-column prop="teacher_name" label="讲者名字" width="120" />
        <el-table-column prop="addtime" label="发布时间" width="120" />
        <el-table-column prop="edittime" label="最后编辑时间" width="180" />
        <el-table-column prop="sort" label="排序号" width="120" />
        <el-table-column prop="view_count" label="观看人数" width="100" />
        <el-table-column fixed="right" label="操作" width="120">
          <template #default="scope">
            <el-button
              v-if="scope.row.seconds == 1"
              type="text"
              size="small"
              @click="addClick(scope.row)"
              >恢复</el-button
            >
            <el-button
              v-else-if="scope.row.seconds == 0"
              type="text"
              size="small"
              @click="handleClick(scope.row)"
              >删除</el-button
            >
            <el-button type="text" size="small" @click="editimage(scope.row)"
              >编辑</el-button
            >
            
            <el-button type="text" size="small" @click="QRcodes(scope.row)"
              >二维码</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="flooter">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        v-model:current-page="currentPage3"
        :page-size="20"
        layout="prev, pager, next ,jumper"
        :total="totalarticles"
      >
      </el-pagination>
    </div>
    <el-dialog title="提示" v-model="dialogVisible" width="30%">
        <div class="flexqrcode">
          <div>
            <div>
              <div><span> 页面二维码 </span></div>
            </div>
            <div id="qrcode"></div>
            <div @click="copytow"><span> 复制 </span></div>
          </div>
          
        </div>
      </el-dialog>
  </div>
</template>
<script>
import { getyzmessagesbypage , aritcletoggledisplay } from '../../../api/index'
import qs from 'qs'
import QRCode from "qrcodejs2";
export default {
  data(){
    return{
      listyizhiyun:[
        {id:0,title:'全网医学会议'},
        {id:1,title:'医学直播'},
        {id:2,title:'医学视频'},
        {id:3,title:'医学博览会(云展厅)'},
      ],
      listyizhinumber:0,
      faterheight:'',
      tableData:[
        
      ],
      tokenlistweb:'wxe019e3ee85d0ced4',
      currentPage3: '',
      // 当前页面的总条
      totalarticles: '',
      idlist:'819202144',
      dialogVisible: false,
    }
  },
  methods:{
    // 选择那个内容
    xuanze(e){
      this.listyizhinumber=e
    },
    // 精选页面
    gotoselected(){
      console.log('精选')
      const { href } = this.$router.resolve({
        path: `/selected`,
        query: { token: this.tokenlistweb, }
      });
      window.open(href, '_blank');
    },
    // 编辑轮播图
    Carousel(){
      const { href } = this.$router.resolve({
        path: `/Carousel`,
        query: { token: this.tokenlistweb, }
      });
      window.open(href, '_blank');
    },
     // 添加
     toaddimage () {
      const { href } = this.$router.resolve({
        path: `/edmit`,
        query: { id: '000', token: this.tokenlistweb, }

      });
      window.open(href, '_blank');
    },
    // 进入全局广告
    advertisement () {
      const { href } = this.$router.resolve({
        path: `/advertisement`,
        query: { token: this.tokenlistweb, }
      });
      window.open(href, '_blank');
    },
    // 页面加载内容
    article () {
      let app = qs.stringify({
        numperpage: 20,//默认20
        pagenumber: 0,
        condition: '',
        token: this.tokenlistweb,
      })
      getyzmessagesbypage(app).then(res => {
        console.log(res)
        this.tableData = res.articles
        this.totalarticles = res.totalCount
      })
    },
    // 编辑
    editimage (e) {
      console.log(e)
      const { href } = this.$router.resolve({
        path: `/edmit`,
        query: { id: e.id, token: this.tokenlistweb, }
      });
      window.open(href, '_blank');
    },
    // 分页
    handleSizeChange (val) {
      console.log(`每页 ${val - 1} 条`);
    },
    // 进入多少页
    handleCurrentChange (val) {
      let vallist = `${val - 1}`
      // console.log(vallist);
      let app = qs.stringify({
        numperpage: 20,//默认20
        pagenumber: vallist,
        token: this.tokenlistweb,
        condition: ''
      })
      getyzmessagesbypage(app).then(res => {
        console.log(res)
        this.tableData = res.articles
        this.totalarticles = res.totalCount
      })
    },
     // 点击不显示
     handleClick (e) {
      console.log(e)
      let userid = JSON.parse(localStorage.getItem("userid"));
      let app = qs.stringify({
        articleid: e.id,
        display: e.seconds,//默认20
        userid: userid,
        token: this.tokenlistweb,
        cridential: '3'
      })
      aritcletoggledisplay(app).then(res => {
        console.log(res)
        if (res.code === '0') {
          e.seconds = res.display
          this.$message({
            message: '修改成功',
            type: 'success'
          });
        } else {
          this.$message.error('失败');
        }
      })
    },
    // 点击显示
    addClick (e) {
      console.log(e)
      let userid = JSON.parse(localStorage.getItem("userid"));
      let app = qs.stringify({
        articleid: e.id,
        display: e.seconds,//默认20
        userid: userid,
        cridential: '3',
        token: this.tokenlistweb,
      })
      aritcletoggledisplay(app).then(res => {
        console.log(res)
        if (res.code === '0') {
          e.seconds = res.display
          this.$message({
            message: '修改成功',
            type: 'success'
          });
        } else {
          this.$message.error('失败');
        }
      })
    },
    QRcodes (e) {
      console.log(e)
      this.dialogVisible = true
        let url = this.tokenlistweb
      console.log(url);
      // let a = url.substr(1);
      // console.log(a);
      let c = url.substring(0, 6);
      console.log(c)
      console.log(this.tokenlistweb)
      if(c=='wx1291'){
        this.offical = `https://wx1291e360dd0cf380.www.tongxianglive.cn/${this.idlist}/#/details?id=${e.id}&wxid=${this.tokenlistweb}`
      }else {
        this.offical = `https://${this.tokenlistweb}.www.tongxianglive.cn/${this.idlist}/#/detailpc?docid=${e.id}`
      }
      console.log(this.offical)
      // let app = qs.stringify({
      //   video_id: e.id
      // })
      // mpqrcode(app).then(res => {
      //   console.log(res)
      //   this.xiao = res
      //   setTimeout(this.chengxuqrcode, 500)
      // })
      setTimeout(this.imageqrcode, 500)
    },
     // 二维码
     imageqrcode () {
      document.getElementById("qrcode").innerHTML = "";
      // 生成
      new QRCode(document.getElementById("qrcode"), {
        text: this.offical, // 这里为动态二维码地址
        width: 100,
        height: 100,
        colorDark: "#000",
        colorLight: "#fff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
  },
  mounted(){
   let a= document.getElementsByClassName("content")[0].offsetHeight;
   let b= document.getElementsByClassName("yizhiheome")[0].offsetHeight;
  //  let c= document.getElementsByClassName("weblistadd")[0].offsetHeight;
   console.log(a,b)
   let c=a-b-32
   console.log(c)
   this.faterheight=c
  },
  created(){
    this.article()
  }
}
</script>
<style scoped>
.yizhiheome {
  /* height: 100vh; */
  /* overflow-y: scroll; */
}
/* 选择模块 */
.xuanze {
  display: flex;
  /* justify-content: center;  */
	/* align-items: center;  */
  font-weight: 600;
  font-size: 20px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
}
.sonlist {
  color: #000;
  margin-right: 15px;
  cursor:pointer
}
.sonlists {
  /* margin-right: 15px; */
  color: #6352F9;
}
/* 按钮 */
.weblistadd {
  display: flex;
  margin-top: 10px;
}
.addarticle {
  padding: 5px 8px;
  margin-right: 15px;
  border-radius: 5px;
  background: #409eff ;
  border: 1px solid #409eff;
  color: #fff;
  font-size: 14px;
  cursor:pointer
}
/* 显示文章 */
.yizhitodayarcter {
  margin-top: 10px;
  overflow-y: scroll;
}
.imsges {
  width: 120px;
  height: 67.5px;
}
/* 样式element */
/deep/.el-table .cell {
  text-align: center !important;
}
/deep/.el-table th > .cell {
  text-align: center !important;
}
/* 分页 */
/* 分页 */
.flooter {
  position: fixed;
  width: 100%;

  bottom: 0;
}
/deep/ .el-pagination {
  margin: 0 auto;
  width: 20%;
}
.flexqrcode {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
/deep/.el-radio-group {
      display: flex;
    /* width: 60%; */
    flex-wrap: wrap
}
/deep/.addarct {
  display: flex;
}
</style>
