<template>
  <div>
    <div @click="webapp">1</div>
  </div>
</template>
<script>
import qs from 'qs'
// 颜色选择器
import { getdefaultnotice, gettuwen } from '../../../api/index'

export default {
  data () {
    return {
      liveid: '',
    }
  },
  methods: {
    // 加载
    async vzanpost () {
      let app = qs.stringify({
        lastid: 0,
        txliveid: this.liveid
      })
      await getdefaultnotice(app).then(res => {
        console.log(res)
      })
    },
    liveidlist () {
      let res = this.$route.query
      this.liveid = res.id
    },
    webapp () {
      var id = localStorage.getItem('userid');
      var token = localStorage.getItem('token');
      let app = qs.stringify({
        txliveid: this.liveid,
        userid: id,
        cridential: token,
      })
      gettuwen(app).then(res => {
        console.log(res)
        for (let i = 0; i < res.tuwen.length; i++) {
          res.tuwen[i].id = i
        }
        this.webapp = res.tuwen
        console.log(this.webapp)
        // this.webimagelive = eval("(" + res.data + ")")
      })
    }
  },
  created () {
    this.liveidlist()
    this.vzanpost()
  },
}
</script>
