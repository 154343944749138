<template>
  <div>
    <div>
      <!-- 频道信息 -->
      <div class="channellistto">
        <i class="iconfont icon-shuxian"></i>
        <span>频道信息</span>
      </div>
      <!-- 设置 -->
      <div class="setuplist">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="基本设置" name="first">
            <!-- 基本设置 -->
            <div class="baicslist">
              <el-form
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                label-width="100px"
                class="demo-ruleForm"
              >
                <el-form-item label="频道名称：" prop="name">
                  <el-input v-model="ruleForm.name"></el-input>
                </el-form-item>
                <el-form-item label="频道类型：" prop="inputlist">
                  <el-input v-model="ruleForm.inputlist" disabled></el-input>
                </el-form-item>
                <el-form-item label="频道封面：">
                  <div class="flexinglist">
                    <div class="imglist">
                      <img :src="text.img" alt="" />
                    </div>
                    <div class="buttomlist">
                      <el-button plain>朴素按钮</el-button>
                      <el-button plain>朴素按钮</el-button>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item label="商业模式：">
                  <div class="titlelistto">
                    <div class="title">开启后频道简介不显示直播间入口</div>
                    <div>
                      <el-switch
                        v-model="value"
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                      >
                      </el-switch>
                    </div>
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </el-tab-pane>
          <el-tab-pane label="配置管理" name="second">配置管理</el-tab-pane>
        </el-tabs>
      </div>
      <!-- 保存 -->
      <div class="savelist">
        <el-button @click="returns">返回</el-button>
        <el-button type="primary" @click="savelist">保存</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      activeName: 'first',
      text: '',
      value: '1',
      ruleForm: {
        name: '',
        inputlist: '公开',
      },
      rules: {
        name: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' }
        ],
        inputlist: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
          { min: 1, max: 5, message: '请选择合法', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    app () {
      let res = this.$route.params
      this.text = res
      this.ruleForm.name = res.num
      console.log(res)
    },
    savelist () {
      if (this.activeName == "first") {
        console.log(1)
      }
      else if (this.activeName == "second") {
        console.log(2)
      }
    },
    returns () {
      this.$router.push('/list')
    }
  },
  created () {
    this.app()
    // console.log(456)
  }
}
</script>
<style>
.setuplist {
  width: 600px;
}
.channellistto {
  /* font-size: 20px; */
  font-weight: 900;
}
.channellistto span {
  font-size: 20px;
}
.channellistto i {
  color: blue;
  font-size: 22px;
}
.flexinglist {
  display: flex;
  height: 200px;
}
.buttomlist {
  height: 100px;
  margin: 160px 10px;
}
.imglist {
  border: 1px solid #ccc;
  width: 200px;
  height: 200px;
}
.imglist img {
  width: 100%;
  height: 100%;
}
.titlelistto {
  display: flex;
}
.titlelistto .title {
  font-size: 14px;
  /* color: ; */
  margin: 0 10px 0 0;
}
.savelist {
  position: fixed;
  bottom: 50px;
  left: 800px;
}
</style>
