<template>
  <div>
    <!-- 直播编辑 -->
    <div class="flexcread">
      <!-- 日历点击 -->
      <div v-if="paginglivelist">
        <el-button type="primary" size="small" @click="addlivelist"
          >添加直播</el-button
        >
      </div>
      <!-- 下拉菜单 -->
    </div>
    <!-- 直播列表 -->
    <div>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="id" label="id" width="180"> </el-table-column>
        <el-table-column label="频道封面" width="180">
          <template #default="scope">
            <img class="imsges" :src="scope.row.videopic" alt="" />
          </template>
        </el-table-column>
        <el-table-column prop="title" label="直播名称" width="200">
        </el-table-column>
        <el-table-column prop="livestatus" label="显示状态" width="100">
          <template #default="scope">
            <span class="el-dropdown-link">
              <span v-if="scope.row.livestatus === 0">未开始</span>
              <span v-else-if="scope.row.livestatus === 1">正在直播</span>
              <span v-else-if="scope.row.livestatus === 2">直播已结束</span>
              <span v-else-if="scope.row.livestatus === 3">直播中断</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="starttime" label="开始时间" width="150">
        </el-table-column>
        <el-table-column prop="typeofurl" label="类别">
          <template #default="scope">
            <span class="el-dropdown-link">
              <span v-if="scope.row.typeofurl === 0">视频</span>
              <span v-else-if="scope.row.typeofurl === 1"> iframe</span>
              <span v-else-if="scope.row.typeofurl === 2">直播</span>
              <span v-else-if="scope.row.typeofurl === 3">回放</span>
              <span v-else-if="scope.row.typeofurl === 4">垫片视频</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template v-slot="scope">
            <!-- <el-tag type="danger" @click="deletelive(scope.row)">删除</el-tag> -->
            <el-tag type="info" @click="startlive(scope.row)">开始</el-tag>
            <el-tag @click="edit(scope.row)">编辑</el-tag>
            <!-- <el-tag @click="livedingedit(scope.row)">编辑2</el-tag> -->
            <el-tag type="danger" @click="endlive(scope.row)">结束</el-tag>
            <el-tag type="success" @click="listmenuabb(scope.row)">菜单</el-tag>
            <el-tag type="warning" @click="testcharbao(scope.row)">报名</el-tag>
            <el-tag @click="officalrelease(scope.row)" v-if="releaselive == 1"
              >发布</el-tag
            >
            <el-tag type="success" v-else-if="releaselive == 2">准备中</el-tag>
            <el-tag type="success" v-else-if="releaselive == 3"
              >{{ timesetup }}%</el-tag
            >
            <el-tag type="warning" @click="preheat(scope.row)">预热</el-tag>
            <el-tag @click="ViewPreheat">查看预热</el-tag>
            <el-tag type="warning" @click="microstation(scope.row)"
              >微站</el-tag
            >
            <el-tag type="success" @click="confingJsLive(scope.row)"
              >confing</el-tag
            >
            <el-tag type="info" @click="controllive(scope.row)"
              >远程控制</el-tag
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div>
      <el-dialog title="选择公众号" v-model="addtokenchoice" width="30%" center>
        <official @childFn="officaltolive" :key="officallist"></official>
      </el-dialog>
    </div>
    <!-- 遮罩编辑页面 -->
    <div>
      <el-dialog title="提示" v-model="list" width="30%" center>
        <!-- 编辑 -->
        <div>
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="editeming"
            label-width="100px"
            class="demo-ruleForm"
            :fullscreen="true"
          >
            <el-form-item label="直播名称" prop="title">
              <el-input v-model="editeming.title"></el-input>
            </el-form-item>
            <el-form-item label="开始时间">
              <!-- <el-input v-model="ruleForm.times"></el-input> -->
              <el-date-picker
                v-model="editeming.starttime"
                type="datetime"
                @click="listtime"
              >
              </el-date-picker>
            </el-form-item>
            <!-- 跳转第三方连接 -->
            <el-form-item label="第三方地址" prop="bypassurl">
              <el-input v-model="editeming.bypassurl"></el-input>
              <div>
                <span>
                  直接跳转到第三方。必须更新config.js才能生效，可以直接改服务器，不需要做npm
                  build，但必须要全网刷新。
                </span>
              </div>
            </el-form-item>
            <!-- 直播选择器 -->
            <el-form-item label="播放器选择" >
              <div class="titletodo">
                <el-dropdown>
                  <span class="el-dropdown-link">
                    <span v-if="editeming.player === 0">阿里</span>
                    <span v-else-if="editeming.player === 1">腾讯</span>
                    {{ editeming.player }}
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <template #dropdown>
                    <el-dropdown-menu v-for="item in playerweb" :key="item.id">
                      <el-dropdown-item
                        @click="playerlive(item.id, item.title)"
                        >{{ item.title }}</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
                <span>仅做状态显示用</span>
              </div>
            </el-form-item>
            <!--  -->
            <el-form-item label="直播状态">
              <div class="titletodo">
                <el-dropdown>
                  <span class="el-dropdown-link">
                    <span v-if="editeming.livestatus === 0">未开始</span>
                    <span v-else-if="editeming.livestatus === 1">正在直播</span>
                    <span v-else-if="editeming.livestatus === 2"
                      >直播已结束</span
                    >
                    <span v-else-if="editeming.livestatus === 3">直播中断</span>
                    {{ editeming.livestatus }}
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <template #dropdown>
                    <el-dropdown-menu v-for="item in dropdown" :key="item.id">
                      <el-dropdown-item
                        @click="wholelive(item.id, item.title)"
                        >{{ item.title }}</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
                <span>仅做状态显示用</span>
              </div>
            </el-form-item>

            <!-- 推流地址 -->
            <el-form-item label="推流地址" prop="stream">
              <!-- <el-input
                :disabled="listdisabled"
                v-model="editeming.stream"
              ></el-input> -->
              <div>
                推流地址：<span id="listtodo">
                  rtmp://pushaly2.tongxianglive.cn/txvod/<span>{{
                    editeming.stream
                  }}</span>
                  <!-- <input type="text" name="" id="input" /> -->
                </span>
                <el-tag @click="copy()" data-clipboard-target=".listtodo"
                  >复制</el-tag
                >
              </div>
              <div>
                拉流地址：
                <span id="listcopytow">
                  rtmp://livealy2.tongxianglive.cn/txvod/<span>{{
                    editeming.stream
                  }}</span>
                </span>
                <el-tag @click="copytow">复制</el-tag>
              </div>
            </el-form-item>
            <!--  -->
            <el-form-item label="直播观看链接" v-if="addemitlive">
              <div>
                <span id="linkcopy">{{ liveurlname }}</span>
                <el-tag @click="linkcopy">复制</el-tag>
                <el-popover placement="right" :width="400" trigger="click">
                  <template #reference>
                    <el-button @click="linkcode" type="primary" plain
                      >生成二维码</el-button
                    >
                  </template>
                  <div id="qrcode" ref="qrcode"></div>
                </el-popover>
              </div>
            </el-form-item>
            <!-- 转发说明 -->
            <el-form-item label="转发说明" prop="intro">
              <el-input v-model="editeming.intro"></el-input>
            </el-form-item>
            <!--  -->
            <!-- 转发图标 -->
            <el-form-item label="转发图标" prop="poster_url">
              <el-upload
                :http-request="upload"
                class="avatar-uploader"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
                :on-change="listtolive"
                :auto-upload="false"
              >
                <img
                  v-if="editeming.poster_url"
                  :src="editeming.poster_url"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <!-- <el-input
                :disabled="listdisabled"
                v-model="editeming.poster_url"
              ></el-input> -->
            </el-form-item>
            <!--  -->
            <!-- <el-form-item label="直播海报">
              <el-upload
                :http-request="posterupload"
                class="avatar-uploader"
                :show-file-list="false"
                :on-success="posterhandleAvatarSuccess"
                :before-upload="posterbeforeAvatarUpload"
                :on-change="posterlisttolive"
                :auto-upload="false"
              >
                <img
                  v-if="editeming.guipicture"
                  :src="editeming.guipicture"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item> -->
            <el-form-item label="直播封面">
              <el-upload
                :http-request="coverposterupload"
                class="avatar-uploader"
                :show-file-list="false"
                :on-success="coverhandleAvatarSuccess"
                :before-upload="coverbeforeAvatarUpload"
                :on-change="coverlisttolive"
                :auto-upload="false"
              >
                <img
                  v-if="editeming.videopic"
                  :src="editeming.videopic"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="轮播图上传">
              <div class="lunbo" v-if="lunbotrfa">
                <el-upload
                  :http-request="lunbouploadone"
                  class="listtoavatar"
                  :show-file-list="false"
                  :on-success="lunbouploadoneAvatarSuccess"
                  :before-upload="beforlunbouploadone"
                  :on-change="lunbouploadonelisttolive"
                  :auto-upload="false"
                >
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <div
                  class="listtoavatar"
                  v-for="item in lunboone"
                  :key="item.id"
                >
                  <i
                    class="el-icon-delete lunbofonts"
                    @click="deletfontlunbo(item.id)"
                  ></i>
                  <img :src="item.src" class="avatar" />
                </div>
              </div>
              <div>
                <el-switch
                  v-model="lunbotrfa"
                  active-text="开启"
                  inactive-text="关闭"
                >
                </el-switch>
              </div>
            </el-form-item>
            <el-form-item label="查看直播留言" v-if="addemitlive">
              <el-button type="primary" @click="leaving" plain
                >直播评论</el-button
              >
            </el-form-item>
            <el-form-item label="数据分析" v-if="addemitlivelist">
              <el-button type="primary" @click="echarts">数据分析</el-button>
            </el-form-item>
            <el-form-item label="强制关注" v-if="addemitlivelist">
              <el-button
                type="danger"
                @click="offlistguanzhu(editeming.typeofvideo)"
                v-if="editeming.typeofvideo == 0"
                >需要</el-button
              >
              <el-button
                type="danger"
                @click="offlistguanzhu(editeming.typeofvideo)"
                v-if="editeming.typeofvideo == 1"
                >不需要</el-button
              >
              <div v-if="editeming.typeofvideo == 1">当前需要</div>
              <div v-if="editeming.typeofvideo == 0">当前不需要</div>
            </el-form-item>
            <el-form-item label="视频" v-if="addemitlive">
              <el-button type="primary" @click="videolivelist">回放</el-button>
            </el-form-item>
            <el-form-item label="发布通知" v-if="addemitlive">
              <el-button type="primary" @click="announcement">通知</el-button>
            </el-form-item>
            <el-form-item label="快速图文编辑" v-if="addemitlive">
              <el-button type="primary" @click="imageWrittenLive"
                >图文控制</el-button
              >
            </el-form-item>
            <el-form-item label="设置登录" v-if="addemitlive">
              <div>
                <div v-for="(item, index) in loginlive" :key="index">
                  <span style="color: blue; margin: 5px">{{ item.title }}</span>
                  <input
                    type="text"
                    placeholder="修改标题"
                    v-model="item.title"
                  />
                  <el-button
                    type="danger"
                    v-if="item.spanlist == 0"
                    @click="loginlistlive(item.spanlist, index)"
                    >打开</el-button
                  >
                  <el-button
                    type="danger"
                    v-if="item.spanlist == 1"
                    @click="loginlistlive(item.spanlist, index)"
                    >关闭</el-button
                  >
                  <div v-if="item.spanlist == 1">当前开启</div>
                  <div v-if="item.spanlist == 0">当前关闭</div>
                </div>
                <!--  -->
              </div>
            </el-form-item>
            <!-- <el-form-item label="公众号" v-if="addemitlive">
              <el-button type="primary" @click="account">绑定公众号</el-button>
            </el-form-item> -->
            <el-form-item label="动态化" v-if="addemitlive">
              <adimation
                :adimationlive="adimationlive"
                :key="comlistlive"
              ></adimation>
            </el-form-item>
            <el-form-item label="动态化" v-if="addemitlive">
              <insidelive
                :insidelist="insidelist"
                :key="comlistlive"
              ></insidelive>
            </el-form-item>
          </el-form>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="listfalseate">取 消</el-button>
            <el-button type="primary" @click="centerDialogVisiblelist"
              >确 定</el-button
            >
          </span>
        </template>
      </el-dialog>
    </div>
    <!-- 分页数--点击去哪页 -->
    <div class="filexd" v-if="paginglivelist">
      <el-pagination
        layout="prev, pager, next"
        :total="paginationlive"
        page-size="20"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <!-- 菜单页面 -->
    <el-dialog v-model="webmenuabb" width="80%" center>
      <intera
        :interaction="interaction"
        @childFn="parentFn"
        :key="com"
      ></intera>
    </el-dialog>
    <!-- 报名 -->
    <el-dialog v-model="baomings" width="80%" center>
      <baoming
        @childFn="livebaomingappshow"
        :baominglist="baominglist"
        :key="coms"
      ></baoming>
    </el-dialog>
  </div>
</template>
<script>
// 数据token切换
import official from './components/officaltoken.vue'
// 后台数据apl
import { edmit, menu, todoceshi, start, stop, change, viewmenu, publish, getpublishstatus, publishcongjstest, pushqlive, checkpushing } from "../../api/index"
// element
import { ElMessage } from 'element-plus'
// qs post连接php
import qs from 'qs';
// 生成二维码
import QRCode from "qrcode2";
// 菜单
import intera from '../interaction/index.vue'
// 报名表添加
import baoming from './components/index.vue'
// 动画设置
import adimation from './components/animation.vue'
import insidelive from './components/insidelive.vue'
// 
// import qs from 'qs';
export default {
  data () {
    return {
      // 下拉菜单点击变内容
      dropdown: [
        { id: 0, title: '未开始' },
        { id: 1, title: '正在直播' },
        { id: 2, title: '直播已结束' },
        { id: 3, title: '直播中断' }
      ],
      playerweb: [
        { id: 0, title: '阿里' },
        { id: 1, title: '腾讯' },
      ],
      // appdownlist: '',
      // 遮罩层
      list: false,
      // 保存编辑内容
      editeming: {
        // player:0
      },
      // 页面数
      valueslistlive: '',
      // 是否禁止
      listdisabled: true,
      // 复制的输入框
      listforflase: false,
      // 图标内容
      imagesfont: '',
      // datetime
      datetime: '',
      link: 'http://wx1291e360dd0cf380.www.tongxianglive.cn/',
      // inputlisttolive: '',
      // 图标上传1.名字
      namelist: '',
      // 图标
      imgfile: '',
      // 后缀
      type: '',
      // 海报上传1.名字
      posternamelist: '',
      // 图标
      posterimgfile: '',
      // 后缀
      postertype: '',
      // 海报上传1.名字
      covernamelist: '',
      // 图标
      coverimgfile: '',
      // 后缀
      covertype: '',
      // 编辑内容
      // ruleForm:''
      // 结束开始
      // liveendstart: false
      // 用户id
      unserid: '',
      token: '',
      tableData: '',
      // 下拉显示的值
      // 轮播上传1.名字
      lunboonenamelist: '',
      // 图标
      lunbooneimgfile: '',
      // 后缀
      lunboonetype: '',
      // 轮播图
      lunboone: [],
      lunbolist: { id: 1, src: '' },
      lunbotrfa: false,
      // 打开菜单
      webmenuabb: false,
      // 传值
      interaction: '',
      // 菜单id
      moneidlist: '',
      // 用于渲染子组件重重新加载
      com: '',
      // 报名
      baomings: false,
      // 报名子
      baominglist: '',
      coms: '',
      // 
      // 生成二维码的名字
      liveurlname: '',
      // 传值id
      adimationlive: '',
      insidelist: '',
      comlistlive: '',
      // 分页是否显示
      paginglivelist: true,
      // 是否显示具体的功能
      addemitlive: true,
      // 页数
      paginationlive: '',
      // 添加token选择
      addtokenchoice: false,
      officallist: '',
      // 是否发布
      releaselive: 1,
      // 需要的id发送
      taskid: '',
      timerlive: '',
      // 发布的%
      timesetup: 0,
      addemitlivelist: true,
      // 设置登录的方式
      loginlive: [
        { id: 0, title: '账号密码', spanlist: 0 },
        { id: 1, title: '白名单', spanlist: 0 },
        { id: 2, title: '默认登录', spanlist: 0 },
      ]
    }
  },

  methods: {
    // 测试
    liveceshi () {
      let id = 1
      todoceshi(id).then(res => {
        console.log(res);
      })
      this.$router.push("./addlive")
    },
    // 点击获取下拉菜单
    wholelive (a, e) {
      console.log(a, e)
      this.editeming.livestatus = a
    },
    playerlive (a, e) {
      console.log(a, e)
      this.editeming.player = a
      console.log(this.editeming.player)
    },
    //     livelist(applisttolive).then(res => {
    //       console.log(res.data);
    //       this.tableData = res.data
    //       this.datetimes()
    //     })
    //   } else if (e == '全部') {
    //     // console.log(2)
    //     todoget().then(res => {
    //       console.log(res.data);
    //       this.tableData = res.data
    //       this.datetimes()
    //     })
    //   }

    // },
    // 点击获取日历
    // datalive (e) {
    //   const date = e.data.day
    //   console.log(date)
    //   // console.log(JSON.stringify(date))
    //   // const apptolive = JSON.parse(date)
    //   const applisttolive = {
    //     query: {
    //       times: date,
    //     },
    //   }
    //   livelist(applisttolive).then(res => {
    //     console.log(res.data);
    //     this.tableData = res.data
    //     this.datetimes()
    //   })
    //   this.livedatelist = false
    //   this.cilcktimes = e.data.day
    //   this.datetimes()
    // },
    // 打开日历
    // noofflive () {
    //   this.livedatelist = true
    // },
    // 获取今天日期及内容
    // 页数
    applist () {
      var id = localStorage.getItem('userid');
      let vals = this.valueslistlive
      let app = qs.stringify({
        pagenumber: vals,
        userid: id
      });
      change(app).then(res => {
        console.log(res);
        this.tableData = res.events
        this.paginationlive = res.totalCount
      })
    },
    // 修改直播内容
    edit (e) {
      this.addemitlive = true
      this.adimationlive = e.id
      this.insidelist = e.id
      this.comlistlive = new Date().getTime()
      this.liveurlname = e.liveurl
      console.log(this.liveurlname)
      this.editeming = e
      console.log(e, e.tab3html)
      // this.inputlisttolive = this.link + this.editeming.id
      // this.appdownlist = e.livestatus
      this.list = true
      if (e.tab3html == '') {
        this.lunbotrfa = false
        this.lunboone = []
      } else if (e.tab3html != '') {
        this.editeming.tab3html = eval("(" + e.tab3html + ")")
        this.lunbotrfa = true
        this.lunboone = this.editeming.tab3html
        // console.log(eval("(" + this.lunboone + ")"))
      }
    },
    // 添加直播
    addlivelist () {

      this.addtokenchoice = true
      this.com = new Date().getTime()

      console.log(this.editeming)
    },
    officaltolive (e) {
      console.log(e)
      this.addtokenchoice = false
      this.addemitlive = false
      this.addemitlivelist = false
      this.list = true
      this.editeming = {
        bypassurl: "",
        guipicture: "",
        id: 0,
        intro: "",
        livestatus: 0,
        poster_url: "",
        starttime: "",
        stream: '',
        title: "",
        typeofurl: '',
        videopic: "",
        view_count: '',
        tab3html: '',
        token: e,
        player:0
      }

    },
    // 添加广告
    // advertisementlive () {
    //   const { href } = this.$router.resolve({
    //     path: `/advertisement`,

    //   });
    //   window.open(href, '_blank');
    // },
    // 编辑确定 开始
    centerDialogVisiblelist () {
      this.upload()
      this.posterupload()
      this.coverupload()
      setTimeout(() => {
        this.backstage()
      }, 2000)
    },
    // 发布
    officalrelease (e) {
      console.log(e)
      let app = qs.stringify({
        txliveid: e.id,
        userid: this.unserid,
        cridential: 2
      })
      this.releaselive = 2
      publish(app).then(res => {
        console.log(res)
        if (res.code == 9999) {
          this.releaselive = 3
          this.taskid = res.taskid
          // this.releaselivelist(e)
          this.timerlive = setInterval(() => {
            this.releaselivelist(e)
          }, 5000)
        }
      })
    },
    // 发布是否成功
    releaselivelist (e) {
      console.log(e)
      if (this.timesetup < 94) {
        this.timesetup = this.timesetup + 4
        console.log(this.timesetup)
      }
      let app = qs.stringify({
        txliveid: e.id,
        userid: this.unserid,
        cridential: 2,
        taskid: this.taskid
      })
      getpublishstatus(app).then(res => {
        console.log(res)
        if (res.status == 2) {
          clearInterval(this.timerlive)
          this.releaselive = 1

          ElMessage({
            showClose: true,
            message: '发布成功',
            type: 'success',
          })
          this.timesetup = 0
        } else if (res.status == 0) {
          clearInterval(this.timerlive)
          this.releaselive = true
          ElMessage({
            showClose: true,
            message: '后台出错请联系管理员',
            type: 'success',
          })
        }

      })
    },
    // 取消
    listfalseate () {
      this.list = false
      this.lunboone = []
    },
    // 向后台传值
    backstage () {
      if (this.lunbotrfa == false) {
        this.editeming.tab3html = ''
        this.lunboone = []
      } else if (this.lunbotrfa == true) {
        this.editeming.tab3html = this.lunboone
        this.lunboone = []
      }
      let value2 = this.editeming.starttime
      console.log(value2)
      if (typeof (value2) == 'string') {
        let id = this.editeming.id
        let data = this.editeming
        let userid = this.unserid
        let token = this.token
        console.log(this.editeming.starttime)
        let app = qs.stringify({
          txliveid: id,
          userid: userid,
          cridential: token,
          data
        })
        edmit(app).then(res => {
          console.log(res);
          // this.tableData = res.events
          if (this.valueslistlive == '') {
            this.applist()
            if (res.code == 0) {
              ElMessage({
                showClose: true,
                message: '成功',
                type: 'success',
              })
              this.list = false
            } else {
              ElMessage({
                showClose: true,
                message: '失败',
                type: 'error',
              })
            }
          } else if (this.valueslistlive != '') {
            this.changelistto()
            if (res.code == 0) {
              ElMessage({
                showClose: true,
                message: '成功',
                type: 'success',
              })
              this.lunboone = []
              this.lunbotrfa = false
              this.list = false
            } else {
              ElMessage({
                showClose: true,
                message: '失败',
                type: 'error',
              })
            }
          }
        })
        // this.valueslistlive

        console.log(this.valueslistlive)
        console.log(this.editeming)
      } else {
        let value2 = this.editeming.starttime
        console.log(1)
        const date = {
          year: value2.getFullYear(),
          month: value2.getMonth() + 1,
          date: value2.getDate(),
          hour: value2.getHours(),//得到小时
          minu: value2.getMinutes(),//得到分钟
          sec: value2.getSeconds(),//得到秒
        };
        const newmonth = date.month >= 10 ? date.month : "0" + date.month;
        const day = date.date >= 10 ? date.date : "0" + date.date;
        const hour = date.hour >= 10 ? date.hour : "0" + date.hour
        const minu = date.minu >= 10 ? date.minu : "0" + date.minu
        const sec = date.sec >= 10 ? date.sec : "0" + date.sec
        let add = date.year + "-" + newmonth + "-" + day + " " + hour + ":" + minu + ":" + sec;
        // console.log(app)
        this.editeming.starttime = add
        let id = this.editeming.id
        let data = this.editeming
        let userid = this.unserid
        let token = this.token
        console.log(this.editeming.starttime, add)
        let app = qs.stringify({
          txliveid: id,
          userid: userid,
          cridential: token,
          data
        })
        edmit(app).then(res => {
          console.log(res);
          // this.tableData = res.events
          if (this.valueslistlive == '') {
            this.applist()
            if (res.code == 0) {
              ElMessage({
                showClose: true,
                message: '更改成功',
                type: 'success',
              })
              this.list = false
            } else {
              ElMessage({
                showClose: true,
                message: '更改失败',
                type: 'error',
              })
            }
          } else if (this.valueslistlive != '') {
            this.changelistto()
            if (res.code == 0) {
              ElMessage({
                showClose: true,
                message: '更改成功',
                type: 'success',
              })
              this.list = false
            } else {
              ElMessage({
                showClose: true,
                message: '更改失败',
                type: 'error',
              })
            }
          }
        })
        // this.valueslistlive
        console.log(this.valueslistlive)
        console.log(this.editeming)
      }
    },
    // 添加当日直播内容
    // 删除
    // deletelive (e) {
    //   // console.log(e)
    //   const id = e._id
    //   console.log(e)
    //   let applist = {
    //     // id: e.id
    //     deleted: 1
    //   }
    //   livedeletelist(id, applist).then(res => {
    //     console.log(res);
    //     // this.listdelete = res
    //     if (res.deleted == 1) {
    //       this.tableData.splice(e, 1)
    //       this.$message({
    //         type: 'success',
    //         message: '删除成功!'
    //       });
    //     } else {
    //       this.$message({
    //         type: 'info',
    //         message: '删除失败'
    //       });
    //     }
    //   })
    // },
    // // 取消删除
    // notdelete () {
    //   this.$message({
    //     type: 'info',
    //     message: '已取消删除'
    //   });
    // },
    // 获取实时时间
    datelistlive () {
      const nowDate = new Date();
      const date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        date: nowDate.getDate(),
        hour: nowDate.getHours(),//得到小时
        minu: nowDate.getMinutes(),//得到分钟
        sec: nowDate.getSeconds(),//得到秒
      };
      const newmonth = date.month > 10 ? date.month : "0" + date.month;
      const day = date.date > 10 ? date.date : "0" + date.date;
      const hour = date.hour > 10 ? date.hour : "0" + date.hour
      const minu = date.minu > 10 ? date.minu : "0" + date.minu
      const sec = date.sec > 10 ? date.sec : "0" + date.sec
      let app = date.year + "-" + newmonth + "-" + day + " " + hour + ":" + minu + ":" + sec;
      this.datetodo = app
      // console.log(app)
      // console.log(this.datetodo++)
      // var int=self.setInterval("clock()",1000);
    },
    // 判断时间
    // <<<<<<< Updated upstream
    //     datetimes () {
    //       console.log(this.tableData)
    //       // console.log(e)
    //       const nowDate = new Date();
    //       const date = {
    //         year: nowDate.getFullYear(),
    //         month: nowDate.getMonth() + 1,
    //         date: nowDate.getDate(),
    //         hour: nowDate.getHours(),//得到小时
    //         minu: nowDate.getMinutes(),//得到分钟
    //         sec: nowDate.getSeconds(),//得到秒
    //       };
    //       console.log(nowDate.getHours() + 2)
    //       const datehour = nowDate.getHours() - 2
    //       const newmonth = date.month > 10 ? date.month : "0" + date.month;
    //       const day = date.date >= 10 ? date.date : "0" + date.date;
    //       const hour = date.hour >= 10 ? date.hour : "0" + date.hour
    //       const datehours = datehour >= 10 ? datehour : "0" + datehour
    //       const minu = date.minu >= 10 ? date.minu : "0" + date.minu
    //       const sec = date.sec >= 10 ? date.sec : "0" + date.sec
    //       let app = date.year + "-" + newmonth + "-" + day + " " + hour + ":" + minu + ":" + sec;
    //       let dateapp = date.year + "-" + newmonth + "-" + day + " " + datehours + ":" + minu + ":" + sec;
    //       let datetime = this.tableData
    //       // console.log(dateapp)
    //       for (let i = 0; i < datetime.length; i++) {
    //         console.log(this.tableData[i].timelist)
    //         // 时间判断，必须同一个格式时间越早越在前边
    //         // 如果开始时间比
    //         if (this.tableData[i].timelist > app) {
    //           this.tableData[i].address = '未开始'
    //           // console.log(this.tableData[i]._id)
    //           let id = this.tableData[i]._id
    //           let applistlive = {
    //             data: {
    //               // _id:id,
    //               address: '未开始'
    //             }
    //           }
    //           editlive(id, applistlive).then((res) => {
    //             console.log(res)
    //             if (res.updated == 1) {
    //               this.tableData[i].address = '未开始'
    //             }
    //           })
    //           console.log('未开始')
    //         } else if (this.tableData[i].timelist <= app && this.tableData[i].timelist > dateapp) {
    //           this.tableData.address = '进行中'
    //           let id = this.tableData[i]._id
    //           let applistlive = {
    //             data: {
    //               // _id:id,
    //               address: '进行中'
    //             }
    //           }
    // =======
    // datetimes () {
    //   console.log(this.tableData)
    //   // console.log(e)
    //   const nowDate = new Date();
    //   const date = {
    //     year: nowDate.getFullYear(),
    //     month: nowDate.getMonth() + 1,
    //     date: nowDate.getDate(),
    //     hour: nowDate.getHours(),//得到小时
    //     minu: nowDate.getMinutes(),//得到分钟
    //     sec: nowDate.getSeconds(),//得到秒
    //   };
    //   console.log(nowDate.getHours() + 2)
    //   const datehour = nowDate.getHours() - 2
    //   const newmonth = date.month > 10 ? date.month : "0" + date.month;
    //   const day = date.date >= 10 ? date.date : "0" + date.date;
    //   const hour = date.hour >= 10 ? date.hour : "0" + date.hour
    //   const datehours = datehour >= 10 ? datehour : "0" + datehour
    //   const minu = date.minu >= 10 ? date.minu : "0" + date.minu
    //   const sec = date.sec >= 10 ? date.sec : "0" + date.sec
    //   let app = date.year + "-" + newmonth + "-" + day + " " + hour + ":" + minu + ":" + sec;
    //   let dateapp = date.year + "-" + newmonth + "-" + day + " " + datehours + ":" + minu + ":" + sec;
    //   let datetime = this.tableData
    //   // console.log(dateapp)
    //   for (let i = 0; i < datetime.length; i++) {
    //     console.log(this.tableData[i].timelist)
    //     // 时间判断，必须同一个格式时间越早越在前边
    //     // 如果开始时间比
    //     if (this.tableData[i].timelist > app) {
    //       this.tableData[i].address = '未开始'
    //       // console.log(this.tableData[i]._id)
    //       let id = this.tableData[i]._id
    //       let applistlive = {
    //         data: {
    //           // _id:id,
    //           address: '未开始'
    //         }
    //       }
    //       editlive(id, applistlive).then((res) => {
    //         console.log(res)
    //         if (res.updated == 1) {
    //           this.tableData[i].address = '未开始'
    //         }
    //       })
    //       console.log('未开始')
    //     } else if (this.tableData[i].timelist <= app && this.tableData[i].timelist > dateapp) {
    //       this.tableData.address = '进行中'
    //       let id = this.tableData[i]._id
    //       let applistlive = {
    //         data: {
    //           // _id:id,
    //           address: '进行中'
    //         }
    //       }

    //       // console.log(res)
    //       editlive(id, applistlive).then((res) => {
    //         // console.log(res)
    //         if (res.updated == 1) {
    //           this.tableData[i].address = '进行中'
    //         }
    //       })
    //       console.log('进行中')
    //     } else if (this.tableData[i].timelist < app && this.tableData[i].timelist < dateapp) {
    //       this.tableData[i].address = '已结束'
    //       let id = this.tableData[i]._id
    //       let applistlive = {
    //         data: {
    //           // _id:id,
    //           address: '已结束'
    //         }
    //       }
    //       editlive(id, applistlive).then((res) => {
    //         // console.log(res.updated)
    //         if (res.updated == 1) {
    //           this.tableData[i].address = '已结束'
    //           console.log(this.tableData[i].address)
    //         }
    //       })

    //       console.log('已结束')
    //     } else {
    //       console.log('？？？')
    //     }
    //   }
    //   // console.log(app)
    // },
    // 开始
    startlive (e) {
      console.log(e)
      let id = e.id
      stop(id).then(res => {
        console.log(res);
        // this.tableData = res.data
        // this.datetimes()
        if (this.valueslistlive == '') {
          this.applist()
          ElMessage({
            showClose: true,
            message: '开始成功',
            type: 'success',
          })
        } else if (this.valueslistlive != '') {
          // this.handleCurrentChange()
          let vals = this.valueslistlive
          let app = qs.stringify({
            pagenumber: vals,
            userid: this.unserid
          });
          change(app).then(res => {
            console.log(res);
            this.tableData = res.events
          })
          ElMessage({
            showClose: true,
            message: '开始成功',
            type: 'success',
          })
        }
      })
      // this.liveendstart = false
    },
    // 结束
    endlive (e) {
      console.log(e)
      let id = e.id

      start(id).then(res => {
        console.log(res);
        // this.tableData = res.data
        // this.datetimes()
        if (this.valueslistlive == '') {
          this.applist()
          ElMessage({
            showClose: true,
            message: '结束成功',
            type: 'success',
          })
        } else if (this.valueslistlive != '') {
          // this.handleCurrentChange()
          let vals = this.valueslistlive
          let app = qs.stringify({
            pagenumber: vals,
            userid: this.unserid
          });
          change(app).then(res => {
            console.log(res);
            this.tableData = res.events
          })
          ElMessage({
            showClose: true,
            message: '结束成功',
            type: 'success',
          })
        }
      })
      // this.liveendstart = true
    },
    // 点击显示条数
    handleCurrentChange (val) {
      var id = localStorage.getItem('userid');
      this.valueslistlive = ` ${val}` - 1
      let vals = ` ${val}` - 1
      console.log(vals, val);
      let app = qs.stringify({
        pagenumber: vals,
        userid: id
      });
      change(app).then(res => {
        console.log(res);
        this.tableData = res.events
      })
    },
    // 返回点击
    changelistto () {
      let vals = this.valueslistlive
      let app = qs.stringify({
        pagenumber: vals,
        userid: this.unserid
      });
      change(app).then(res => {
        console.log(res);
        this.tableData = res.events
      })
    },
    // 复制
    copy () {
      // console.log(data)
      let res = document.getElementById('listtodo').innerText
      console.log(res)
      var input = document.createElement("input"); // 直接构建input
      input.value = res // 设置内容
      console.log(input.value);
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input);
      ElMessage({
        showClose: true,
        message: '复制成功',
        type: 'success',
      })
      // const input = document.getElementById('input')
      // // 复制
      // input.value = res; // 修改文本框的内容
      // input.select();
      // document.execCommand('copy')

    },
    // 啦刘复之
    copytow () {
      // console.log(data)
      let res = document.getElementById('listcopytow').innerText
      console.log(res)
      var input = document.createElement("input"); // 直接构建input
      input.value = res // 设置内容
      console.log(input.value);
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input);
      ElMessage({
        showClose: true,
        message: '复制成功',
        type: 'success',
      })
    },
    linkcopy () {
      let res = document.getElementById('linkcopy').innerText
      console.log(res)
      var input = document.createElement("input"); // 直接构建input
      input.value = res // 设置内容
      console.log(input.value);
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input);
      ElMessage({
        showClose: true,
        message: '复制成功',
        type: 'success',
      })
    },
    // 生成二维码
    linkcode () {
      document.getElementById("qrcode").innerHTML = "";
      // 生成
      new QRCode(document.getElementById("qrcode"), {
        text: this.liveurlname, // 这里为动态二维码地址
        width: 100,
        height: 100,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
    listtime () {
      console.log(1)
    },
    // 图标上传开始
    // handleAvatarSuccess (res, file) {
    //   this.imagesfont = URL.createObjectURL(file.raw);
    //   this.editeming.poster_url = this.imagesfont
    //   console.log(file)
    //   // console.log(this.imageUrl)
    //   // console.log(res)
    // },
    beforeAvatarUpload (file) {
      console.log(file)
      const isJPG = file.type === 'image/jpeg';
      const isPng = file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isJPG && !isPng) {
        this.$message.error('上传头像图片只能是 JPG 和png 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    // // 进行中
    listtolive (file) {
      console.log(file.raw.type)
      const isJPG = file.raw.type === 'image/jpeg';
      const isPng = file.type === 'image/png'
      const isLt2M = file.raw.size / 1024 / 1024 < 2;
      console.log(URL.createObjectURL(file.raw))
      if (!isJPG && !isPng) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      } else if (!isLt2M) {
        console.log(isJPG)
        this.$message.error('上传头像图片大小不能超过 2MB!');
      } else {
        this.type = file.raw.type
        this.namelist = file.name
        // console.log(111)
        this.imgfile = file.raw
        this.editeming.poster_url = URL.createObjectURL(file.raw)
      }
    },
    // 上传
    upload () {
      if (this.imgfile != undefined && this.imgfile != '') {
        console.log(this.imgfile)
        var COS = require('cos-js-sdk-v5');
        var cos = new COS({
          SecretId: 'AKIDUtGahY9UYLr52i7F6gz1mGTTDi4KOBiy',
          SecretKey: '1YtymIZZJmytXuzoMddMozsUpthEVVDe'
        });
        this.cosdata = cos
        let data = new Date().getTime()
        let random = Math.floor(Math.random() * 100);
        let image = this.imgfile
        let name = this.namelist
        let namelist = data + '-' + random + '-' + name
        console.log(namelist)
        this.cosdata.putObject({
          Bucket: 'txcos-1306887409', /* 必须 */
          Region: 'ap-beijing', /* 存储桶所在地域，需要后端提供 */
          Key: namelist, /* 必须 */
          StorageClass: 'STANDARD',
          Body: image, // 上传文件对象
          // FilePath: 'tx4s',
          onProgress: (progressData) => {
            console.log(JSON.stringify(progressData));
            // console.log(progressData)
          }
        }, (err, data) => {
          console.log(err || data);
          console.log(data)
          // if (data.statusCode == 200) {
          //   if (this.type == 'image/jpeg') {
          //     this.type = 'jpg'
          //     let image = data.Location + this.type
          // this.editeming.poster_url = data.Location
          this.editeming.poster_url = 'https://' + data.Location
          this.imgfile = ''
          // } else if (this.type == 'image/png') {
          //   this.type = 'png'
          //   let image = data.Location + this.type
          //   console.log(image)
          //   this.editeming.poster_url = image
          // }

          // }

        });
      } else {
        console.log('第yi 个')
      }
    },
    // 图标上传结束

    // 直播海报上传
    posterbeforeAvatarUpload (file) {
      console.log(file)
      const isJPG = file.type === 'image/jpeg';
      const isPng = file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isJPG && !isPng) {
        this.$message.error('上传头像图片只能是 JPG 和png 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    // // 进行中
    posterlisttolive (file) {
      console.log(file.raw.type)
      const isJPG = file.raw.type === 'image/jpeg';
      const isPng = file.type === 'image/png'
      const isLt2M = file.raw.size / 1024 / 1024 < 2;
      console.log(URL.createObjectURL(file.raw))
      if (!isJPG && !isPng) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      } else if (!isLt2M) {
        console.log(isJPG)
        this.$message.error('上传头像图片大小不能超过 2MB!');
      } else {
        this.postertype = file.raw.type
        this.posternamelist = file.name
        // console.log(111)
        this.posterimgfile = file.raw
        this.editeming.guipicture = URL.createObjectURL(file.raw)
      }
    },
    // 上传
    posterupload () {
      if (this.posterimgfile != '' && this.posterimgfile != undefined) {
        console.log(this.posterimgfile)
        var COS = require('cos-js-sdk-v5');
        var cos = new COS({
          SecretId: 'AKIDUtGahY9UYLr52i7F6gz1mGTTDi4KOBiy',
          SecretKey: '1YtymIZZJmytXuzoMddMozsUpthEVVDe'
        });
        this.cosdata = cos
        let data = new Date().getTime()
        let random = Math.floor(Math.random() * 100);
        let image = this.posterimgfile
        let name = this.posternamelist
        let namelist = data + '-' + random + '-' + name
        console.log(namelist)
        this.cosdata.putObject({
          Bucket: 'txcos-1306887409', /* 必须 */
          Region: 'ap-beijing', /* 存储桶所在地域，需要后端提供 */
          Key: namelist, /* 必须 */
          StorageClass: 'STANDARD',
          Body: image, // 上传文件对象
          // FilePath: 'tx4s',
          onProgress: (progressData) => {
            console.log(JSON.stringify(progressData));
            // console.log(progressData)
          }
        }, (err, data) => {
          // console.log(err || data);
          console.log(data)
          // if (data.statusCode == 200) {
          //   if (this.postertype == 'image/jpeg') {
          //     this.postertype = 'jpg'
          //     let image = data.Location + this.postertype

          // this.editeming.guipicture = data.Location
          this.editeming.guipicture = 'https://' + data.Location
          this.posterimgfile = ''
          //   } else if (this.postertype == 'image/png') {
          //     this.postertype = 'png'
          //     let image = data.Location + this.postertype
          //     this.editeming.guipicture = image
          //   }

          // }

        });
      } else {
        console.log('第三个')
      }
    },
    // 直播海报结束
    // 直播封面
    coverbeforeAvatarUpload (file) {
      console.log(file)
      const isJPG = file.type === 'image/jpeg';
      const isPng = file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isJPG && !isPng) {
        this.$message.error('上传头像图片只能是 JPG 和png 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    // // 进行中
    coverlisttolive (file) {
      console.log(file.raw.type)
      const isJPG = file.raw.type === 'image/jpeg';
      const isPng = file.raw.type === 'image/png'
      const isLt2M = file.raw.size / 1024 / 1024 < 2;
      console.log(URL.createObjectURL(file.raw))
      if (!isJPG && !isPng) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      } else if (!isLt2M) {
        console.log(isJPG)
        this.$message.error('上传头像图片大小不能超过 2MB!');
      } else {
        this.covertype = file.raw.type
        this.covernamelist = file.name
        // console.log(111)
        this.coverimgfile = file.raw
        this.editeming.videopic = URL.createObjectURL(file.raw)
      }
    },
    coverupload () {
      if (this.coverimgfile != '' && this.coverimgfile != undefined) {
        console.log(this.coverimgfile)
        var COS = require('cos-js-sdk-v5');
        var cos = new COS({
          SecretId: 'AKIDUtGahY9UYLr52i7F6gz1mGTTDi4KOBiy',
          SecretKey: '1YtymIZZJmytXuzoMddMozsUpthEVVDe'
        });
        this.cosdata = cos
        let data = new Date().getTime()
        let random = Math.floor(Math.random() * 100);
        let image = this.coverimgfile
        let name = this.covernamelist
        let namelist = data + '-' + random + '-' + name
        console.log(namelist)
        this.cosdata.putObject({
          Bucket: 'txcos-1306887409', /* 必须 */
          Region: 'ap-beijing', /* 存储桶所在地域，需要后端提供 */
          Key: namelist, /* 必须 */
          StorageClass: 'STANDARD',
          Body: image, // 上传文件对象
          // FilePath: 'tx4s',
          onProgress: (progressData) => {
            console.log(JSON.stringify(progressData));
            // console.log(progressData)
          }
        }, (err, data) => {
          console.log(err || data);
          console.log(data)
          // if (data.statusCode == 200) {
          //   console.log(123)
          //   if (this.covertype == 'image/jpeg') {
          //     console.log('jpg')
          //     this.covertype = 'jpg'
          //     let image = data.Location + this.covertype

          this.editeming.videopic = 'https://' + data.Location
          this.coverimgfile = ''
          console.log(this.editeming.videopic)
          //     console.log(this.editeming.videopic)
          //   } else if (this.covertype == 'image/png') {
          //     console.log('png')
          //     this.covertype = 'png'
          //     let image = data.Location + this.covertype
          //     this.editeming.videopic = image
          //     console.log(image)
          //     console.log(this.editeming.videopic)
          //   } else {
          //     console.log('啥也不是')
          //     console.log(this.covertype)
          //   }

          // }

        });
      } else {
        console.log(123)
      }
    },
    // 直播封面结束
    // id
    iduser () {
      var id = localStorage.getItem('userid');
      var token = localStorage.getItem('token');
      // console.log(id, token)
      this.unserid = id
      this.token = token
      var quanxian = localStorage.getItem('quanxian');
      if (quanxian > 819202100 && quanxian < 819999999) {
        this.paginglivelist = false
      } else {
        this.paginglivelist = true
      }
    },
    // 轮播
    beforlunbouploadone (file) {
      console.log(file)
      const isJPG = file.type === 'image/jpeg';
      const isPng = file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isJPG && !isPng) {
        this.$message.error('上传头像图片只能是 JPG 和png 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    // 轮播图1进行
    lunbouploadonelisttolive (file) {
      console.log(file.raw.type)
      const isJPG = file.raw.type === 'image/jpeg';
      const isPng = file.raw.type === 'image/png'
      const isLt2M = file.raw.size / 1024 / 1024 < 2;
      console.log(URL.createObjectURL(file.raw))
      if (!isJPG && !isPng) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      } else if (!isLt2M) {
        console.log(isJPG)
        this.$message.error('上传头像图片大小不能超过 2MB!');
      } else {
        this.lunboonetype = file.raw.type
        this.lunboonenamelist = file.name
        // console.log(111)
        this.lunbooneimgfile = file.raw
        this.lunbouploadoneupload()
        // this.lunboone = URL.createObjectURL(file.raw)
      }
    },
    // 轮播1上传
    lunbouploadoneupload () {
      if (this.lunbooneimgfile != '' && this.lunbooneimgfile != undefined) {
        console.log(this.lunbooneimgfile)
        var COS = require('cos-js-sdk-v5');
        var cos = new COS({
          SecretId: 'AKIDUtGahY9UYLr52i7F6gz1mGTTDi4KOBiy',
          SecretKey: '1YtymIZZJmytXuzoMddMozsUpthEVVDe'
        });
        this.cosdata = cos
        let data = new Date().getTime()
        let random = Math.floor(Math.random() * 100);
        let image = this.lunbooneimgfile
        let name = this.lunboonenamelist
        let namelist = data + '-' + random + '-' + name
        console.log(namelist)
        this.cosdata.putObject({
          Bucket: 'txcos-1306887409', /* 必须 */
          Region: 'ap-beijing', /* 存储桶所在地域，需要后端提供 */
          Key: namelist, /* 必须 */
          StorageClass: 'STANDARD',
          Body: image, // 上传文件对象
          // FilePath: 'tx4s',
          onProgress: (progressData) => {
            console.log(JSON.stringify(progressData));
            // console.log(progressData)
          }
        }, (err, data) => {
          console.log(err || data);
          console.log(data)
          this.lunbolist.src = 'https://' + data.Location
          let app = { id: this.lunbolist.id, src: this.lunbolist.src }
          console.log(app, this.lunbolist)
          this.lunboone.push(app)
          console.log(this.lunboone)
          for (let i = 0; i < this.lunboone.length; i++) {
            this.lunboone[i].id = i
          }
          console.log(this.lunboone)
        });
      } else {
        console.log(123)
      }
    },
    // 删除
    deletfontlunbo (e) {
      // console.log(e)
      this.lunboone.splice(e, 1)
      for (let i = 0; i < this.lunboone.length; i++) {
        this.lunboone[i].id = i
      }
    },
    // 打开菜单
    listmenuabb (e) {
      this.com = new Date().getTime()
      this.webmenuabb = true
      console.log(e.id)
      // 菜单id
      this.moneidlist = e.id
      console.log(this.moneidlist)
      // 查询菜单
      let id = this.moneidlist
      let userid = e.id
      let token = this.token
      let app = qs.stringify({
        txliveid: id,
        userid: userid,
        cridential: token,
      })
      viewmenu(app).then(res => {
        console.log(res)
        if (res.data == '') {
          console.log('1')
          this.interaction = []
        } else {
          let instar = eval("(" + res.data + ")")
          console.log(instar)
          this.interaction = instar
        }
      })
      // if()
    },
    // 接受子传值
    parentFn (payload) {
      console.log(payload)
      let id = this.moneidlist
      let data = payload
      let userid = this.unserid
      let token = this.token
      // console.log(this.editeming.starttime)
      let app = qs.stringify({
        txliveid: id,
        userid: userid,
        cridential: token,
        data: data
      })
      menu(app).then(res => {
        console.log(res)
        if (res.code == 0) {
          this.webmenuabb = false
          alert('创建成功')
          // this.interaction = ''
        } else {
          alert('创建失败，请重试')
        }
      })
    },
    // 报名表
    testcharbao (e) {
      console.log(e)
      this.baomings = true
      let app = {
        liveid: e.id,
        livebaomingapp: e.sub_cate_id
      }
      this.coms = new Date().getTime()
      this.baominglist = app
    },
    // 接受报名传值
    livebaomingappshow (e) {
      console.log(e)
      if (e == '1') {
        console.log('1')
        this.applist()
      }
    },
    // 进入留言页面
    leaving () {
      console.log(this.editeming.id)
      this.$router.push(
        {
          path: '/leaving',
          query: { liveid: this.editeming.id }
        }
      )
    },
    // 进入数据分析界面
    echarts () {
      console.log()
      const { href } = this.$router.resolve({
        path: `/echarts`,
        query: {
          id: this.editeming.id
        }
      });
      window.open(href, '_blank');
    },
    // 回放
    videolivelist () {
      console.log()
      const { href } = this.$router.resolve({
        path: `/videolive`,
        query: {
          id: this.editeming.id,
          img: this.editeming.videopic
        }
      });
      window.open(href, '_blank');
    },
    // 通知页面
    announcement () {
      const { href } = this.$router.resolve({
        path: `/announcementlive`,
        query: {
          id: this.editeming.id,

        }
      });
      window.open(href, '_blank');
    },
    // 快速图文切换
    imageWrittenLive () {
      const { href } = this.$router.resolve({
        path: `/image`,
        query: {
          id: this.editeming.id,
        }
      });
      window.open(href, '_blank');
    },
    // 更换公众号
    // account () {
    //   const { href } = this.$router.resolve({
    //     path: `/official`,
    //     query: {
    //       id: this.editeming.id,
    //     }
    //   });
    //   window.open(href, '_blank');
    // },
    // 进入微站页面
    microstation (e) {
      console.log(e)
      const { href } = this.$router.resolve({
        path: `/MicroStation`,
        query: {
          id: e.id,
          baomingid: e.sub_cate_id
        }
      });
      window.open(href, '_blank');
    },

    // 更新confing文件
    confingJsLive (e) {
      console.log(e)
      let id = e.id
      publishcongjstest(id).then(res => {
        console.log(res)
      })
    },
    // 远程控制
    controllive (e) {
      console.log(e)
      const { href } = this.$router.resolve({
        path: `/control`,
        query: {
          id: e.id,
        }
      });
      window.open(href, '_blank');
    },
    // 预热
    preheat (e) {
      console.log(e)
      let app = qs.stringify({
        txliveid: e.id
      })
      pushqlive(app).then(res => {
        console.log(res)
        if (res == 2) {
          ElMessage({
            showClose: true,
            message: '预热进行',
            type: 'success',
          })
        } else {
          ElMessage({
            showClose: true,
            message: '出错了请重试或联系管理员',
            type: 'error',
          })
        }
      })
    },
    // 查看预热
    ViewPreheat () {
      checkpushing().then(res => {
        console.log(res)
        if (res == 0) {
          ElMessage({
            showClose: true,
            message: '出错了请联系管理员',
            type: 'error',
          })
        } else if (res == 1) {
          ElMessage({
            showClose: true,
            message: '预热进行中',
            type: 'success',
          })
        } else if (res == 2) {
          ElMessage({
            showClose: true,
            message: '预热成功',
            type: 'success',
          })
        }
      })
    },
    // 是否要强制关注
    offlistguanzhu (e) {
      console.log(e)
      if (e == 1) {
        this.editeming.typeofvideo = 0
      } else if (e == 0) {
        this.editeming.typeofvideo = 1
      }
      console.log(this.editeming.typeofvideo)
    },
    // 设置登录方式
    loginlistlive (e, a) {
      // console.log(this.loginlive)
      console.log(e, a)
      if (e == 1) {
        this.loginlive[a].spanlist = 0
      } else if (e == 0) {
        this.loginlive[a].spanlist = 1
      }
    },
  },
  created () {
    this.iduser()
    this.valueslistlive = 0
    this.applist()
    this.datelistlive()
    var quanxian = localStorage.getItem('quanxian');
    console.log(quanxian)
    if (quanxian == 1 || quanxian == 2) {
      this.addemitlivelist = true
    } else {
      this.addemitlivelist = false
    }
    // this.handleCurrentChange=0

    // this.datetimes()
  },
  mounted () {
    this.datetodo = setInterval(this.datetimes, 60000);
    //  this.$once('hook:beforeDestroy', () => clearInterval(timer);)
    console.log(this.datetodo)
  },
  components: {
    intera,
    baoming,
    adimation,
    insidelive,
    official
  }
  // beforeDestroy () {
  //   clearTimeout(this.datetodo);
  // }
  //    beforeDestroy: function () {
  //            console.log("我已经离开了！");
  //     this.stopTimer();
  //  },
}
</script>
<style>
/* 获取日期 */
.datelistto {
  height: 100%;
}
.content {
  background-color: #f0f0f0;
}
/* 直播编辑 */
.flexcread {
  padding-bottom: 5px;
}
/* 下拉菜单 */
.listapp {
  margin: 7px 0;
  border: 1px solid #409eff;
  border-radius: 3px;
  padding: 4px;
  background-color: #d9ecff;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
/* 下拉菜单样式 */
/* 直播封面 */
.imsges {
  width: 120px;
  height: 67.5px;
}
/* 浮动变成手势 */
.applist:hover {
  cursor: hand !important;
}
/* 操作 */
.el-tag {
  margin: 5px;
}
/* 直播编辑 */
.colorlive {
  margin: 3px 0;
  color: #409eff;
  font-size: 20px;
  font-weight: 600;
  border: 1px solid #409eff;
  width: 300px;
  text-align: center;
  background-color: #fff;
}
.el-button {
  padding: 8px 8px !important;
  min-height: 7px;
}
.el-dialog {
  width: 70% !important;
}
.avatar {
  width: 100%;
  height: 100%;
}
.filexd {
  position: fixed;
  bottom: 3px;
  left: 45%;
}
.el-pagination {
  background-color: #bebebe;
}
.lunbo {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.listtoavatar {
  margin: 10px;
  width: 160px;
  height: 90px;
  position: relative;
}
.listtoavatar .el-upload--text {
  width: 100% !important;
  height: 100% !important;
}
.lunbofonts {
  position: absolute;
  top: 10px;
  right: 10px;
  color: red;
}
.el-tag {
  padding: 0 7px !important;
}
</style>
