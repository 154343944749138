<template>
  <div>
    <!-- 使用内部链接 -->
    <el-button
      v-for="(item, index) in weblist"
      :key="index"
      type="info"
      size="small"
      @click="insidelivelist(item)"
      plain
      >{{ item.title }}</el-button
    >
    <span>当前为：{{ list }}状态</span>
  </div>
</template>
<script>
import qs from 'qs';
import { wscontrolcommand } from '../../../api/index'
export default {
  data () {
    return {
      list: '关闭',
      liveid: '',
      weblist: [
        { id: 0, title: '打开窗口', liveidweb: 4 },
        { id: 1, title: '使用外部', liveidweb: 5 },
        { id: 2, title: '使用窗口', liveidweb: 6 }
      ]
    }
  },
  props: ['insidelist'],
  methods: {
    insidelivelist (e) {
      this.list = e.title
      let app = qs.stringify({
        command: e.liveidweb,
        txliveid: this.liveid,
      });
      wscontrolcommand(app).then(res => {
        console.log(res);

      })
    }
  },
  created () {
    console.log(this.insidelist)
    this.liveid = this.insidelist
  }
}
</script>
