<template>
  <div>
    <!-- 公众号 -->

    <!-- 单选 -->
    <div>
      <div
        style="
          margin: 16px 0;
          font-weight: 900;
          padding: 5px;

          width: 100px;
          text-align: center;
        "
      >
        <!-- 进行选择 -->
      </div>
      <el-radio-group
        v-model="radio"
        v-for="(item, index) in weboff"
        :key="index"
      >
        <el-radio :label="item.token" @click="boxlist(item)">{{
          item.gongzhonghao
        }}</el-radio>
      </el-radio-group>
    </div>
    <div style="margin: 10px 0">
      <el-button type="primary" @click="liveing">确定选择</el-button>
    </div>
  </div>
</template>
<script>
// qs post连接php
import qs from 'qs';
import { gettokenbytxliveid } from '../../../api/index'
export default {

  data () {
    return {
      radio: '',
      weboff: [
      ],
      liveid: '',
      // 选择的token
      tokenlive: ''
    }
  },
  methods: {
    boxlist (e) {
      console.log(e, this.radio)
      this.tokenlive = e.token
    },
    // 确定

    // 加载获取
    creaofficai () {
      var userid = JSON.parse(localStorage.getItem("userid"));
      let app = qs.stringify({
        txliveid: this.liveid,
        userid: userid,
        cridential: '1',
      })
      gettokenbytxliveid(app).then(res => {
        console.log(res)
        this.weboff = res.tokens
        for (var i = 0; i < res.tokens.length; i++) {
          if (res.tokens[i].selected == 1) {
            this.radio = res.tokens[i].token
            this.tokenlive = res.tokens[i].token
            console.log(this.radio)
          }
        }
      })
    },
    // 确定
    liveing () {
      console.log(this.tokenlive)
      this.$emit('childFn', this.tokenlive)
      this.tokenlive = ''
    },
  },
  created () {
    // let app = this.$route.query
    // this.liveid = app.id
    this.creaofficai()
  }
}
</script>
