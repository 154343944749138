<template>
  <div>
    <div class="crumbs"></div>
    <div class="container">
      <div class="mgb20" ref="editor"></div>
      <el-button type="primary" @click="syncHTML">保存</el-button>
    </div>
  </div>
</template>

<script>
import Wangeditor from "wangeditor";
import { ref, reactive, onMounted, onBeforeUnmount, } from "vue";
// import {defineProps, defineProps} from 'vue'

export default {
  name: "editor",
  // props: {
  //   quil: {
  //     type: String
  //   },
  //   default: {}
  // },

  setup (props, { emit }) {
    const editor = ref(null);
    const content = reactive({
      html: "",
      text: "",
    });
    // console.log(props)
    // console.log(setTimeout(props, 700))
    var instance;
    onMounted(() => {
      instance = new Wangeditor(editor.value);
      instance.config.zIndex = 1;
      instance.config.showLinkImg = false
      var COS = require('cos-js-sdk-v5');
      var cos = new COS({
        SecretId: 'AKIDUtGahY9UYLr52i7F6gz1mGTTDi4KOBiy',
        SecretKey: '1YtymIZZJmytXuzoMddMozsUpthEVVDe'
      });
      instance.config.customUploadImg = function (resultFiles, insertImgFn) {
        const file = resultFiles[0];
        let data = new Date().getTime()
        let random = Math.floor(Math.random() * 100);
        let namelist = data + '-' + random + '-' + file
        cos.sliceUploadFile({
          Bucket: 'txcos-1306887409', /* 必须 */
          Region: 'ap-beijing', /* 存储桶所在地域，需要后端提供 */
          Key: namelist, // 文件名称
          StorageClass: 'STANDARD',
          Body: resultFiles[0], // 文件
        }, function (err, data) {
          if (err) {
            console.log(err);
            return;
          }
          insertImgFn('//' + data.Location); // 插入返回的url地址
          console.log(data.Location)
        });
      };
      instance.create();
    });
    onBeforeUnmount(() => {
      instance.destroy();
      instance = null;
    });
    const syncHTML = () => {
      // console.log(instance.txt.editor.toolbarSelector.innerText)
      // instance.txt.editor.toolbarSelector.innerText=content.html
      // let app = this.$refs.editor.getConfig()
      content.html = instance.txt.html();
      var list = content.html
      emit("childFn", list);
      // content.html = ''
    }
    return {
      syncHTML,
      editor,
      content,
    };
  },
  props: ['quil'],
  methods: {
    crea () {
      this.content.html = this.quil
      // console.log(instance)
    }
  },
  created () {
    setTimeout(this.crea, 1000)
  }
};
</script>

<style>
</style>