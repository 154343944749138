<template>
  <div class="over">
    <!-- 头部 -->
    <div class="loop">
      <div class="live">
        <div class="img">
          <img
            class="image"
            src="https://img2.baidu.com/it/u=3566088443,3713209594&fm=26&fmt=auto&gp=0.jpg"
            alt=""
          />
        </div>
        <div class="title">
          <div class="room">
            <span>666的直播间</span>
            <img class="imggs" src="../images/1.png" alt="" />
            <img class="imggs" src="../images/2.png" alt="" />
          </div>
          <div class="bottom">
            <el-button type="primary">免费版</el-button>
            <el-button>收费版</el-button>
            <span class="span">立即升级</span>
          </div>
        </div>
      </div>
      <div class="right">
        <img class="imggs" src="../images/3.png" alt="" />
        <span class="liss">如何创建投票</span>
        <span class="liss">查看更多</span>
        <img class="imggs" src="../images/4.png" alt="" />
      </div>
    </div>
    <!-- 头部结束 -->
    <!-- 下面所有 -->
    <div class="all">
      <!-- 左侧 -->
      <div class="leftlist">
        <!-- 信息 -->
        <div class="information">
          <!-- 资产 -->
          <div class="assets">
            <!-- tou -->
            <div class="cash">
              <div class="wight"><span>资产信息</span></div>
              <div class="small">
                <span>去提现</span> |
                <span>查看更多</span>
              </div>
            </div>
            <!-- 收入访问 -->
            <div class="Income">
              <div class="sales">
                <div class="total">总收入</div>
                <div>
                  <span class="wightlist">$0</span>
                  <span>本周0</span>
                </div>
              </div>
              <div class="sales">
                <div class="total">总收入</div>
                <div>
                  <span class="wightlist">$0</span>
                  <span>本周0</span>
                </div>
              </div>
            </div>
          </div>
          <div class="assets">
            <!-- tou -->
            <div class="cash">
              <div class="wight"><span>资产信息</span></div>
              <div class="small">
                <span>去提现</span> |
                <span>查看更多</span>
              </div>
            </div>
            <!-- 收入访问 -->
            <div class="Income">
              <div class="sales">
                <div class="total">总收入</div>
                <div>
                  <span class="wightlist">$0</span>
                  <span>本周0</span>
                </div>
              </div>
              <div class="sales">
                <div class="total">总收入</div>
                <div>
                  <span class="wightlist">$0</span>
                  <span>本周0</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 信息结束 -->
        <!-- 代办直播 -->
        <div class="agent">
          <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
          >
            <el-menu-item index="1">处理中心</el-menu-item>
            <el-menu-item index="4">123</el-menu-item>
          </el-menu>

          <!-- 代办类表 -->
          <!-- <div class="broadcast">
            <div class="one">代办直播</div>
            <div>查看直播列表</div>
          </div> -->
          <!-- 直播 -->
          <!-- <div></div> -->
        </div>
      </div>
      <!-- 右侧 -->
      <div class="rightlist"></div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      activeIndex: '1',
      activeIndex2: '1'
    };
  },
  methods: {
    handleSelect (key, keyPath) {
      console.log(key, keyPath);
    }
  }

}
</script>
<style>
/* 头部头像加文字 */
.live {
  display: flex;
}
.img {
  height: 50px;
  width: 50px;
}
.image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
/* 文字按钮 */
.title {
  margin-left: 20px;
}
.room {
  font-size: 16px;
  font-weight: 900;
}
.imggs {
  margin: 0 0 0 3px;
  height: 13px;
  width: 15px;
}
.el-button {
  height: 30px !important;
  min-height: 0 !important;
  line-height: 0 !important;
}
.span {
  margin: 5px;
  color: #1890ff;
  font-size: 14px;
}
/* 右侧 */
.loop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.right {
  margin: 15px 0;
}
.liss {
  margin: 0 0 0 10px;
  font-size: 14px;
  color: #ccc;
}
/* 头部结束 */
/* 所有 */
.all {
  margin-top: 20px;
  background-color: red;
  /* height: 20px; */
  width: 100%;
  display: flex;
}
/* 左侧 */
.leftlist {
  flex: 7;
}
.information {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.assets {
  background-color: white;
  flex: 4;
  margin-left: 10px;
}
.cash {
  /* width: 80%; */

  /* margin: 0 auto; */
  border-bottom: 1px solid #ccc;
  display: flex;
  flex-direction: row;
  padding: 20px;
  justify-content: space-between;
}
.wight {
  font-size: 16px;
  font-weight: 900;
}
.small {
  font-size: 14px;
  color: #ccc;
}
/* 收入访问 */
.Income {
  margin: 20px 0;
  padding: 20px;
  display: flex;
}
.sales {
  flex: 5;
}
.total {
  color: #ccc;
  margin: 10px;
  font-size: 14px;
}
.wightlist {
  font-size: 24px;
  font-weight: 900;
  margin-right: 5px;
}
/* 代班直播 */
.el-menu--horizontal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
/* .agent {
  margin-top: 20px;
  width: 100%;

  box-sizing: content-box;
}
.broadcast {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  border-bottom: 1px solid #ccc;
  margin-left: 10px;
  box-sizing: border-box;
  padding: 5px 0;
}
.broadcast .one {
  color: #1890ff;
  padding: 5px 0;
  border-bottom: 2px solid #1890ff;
}
*/
/* 右侧 */
.rightlist {
  flex: 3;
}
</style>