<template>
  <div class="">
    <div class="nav">
      <div class="jurisd">用户权限管理</div>
      <div>
        <el-button type="primary" size="small " @click="julistction" plain
          >创建单个管理</el-button
        >
        <el-button type="primary" size="small " plain>主要按钮</el-button>
      </div>
    </div>
    <div class="userweb">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="username" label="用户名" width="180" />
        <el-table-column prop="userid" label="账号" width="180" />
        <el-table-column label="权限" width="180">
          <template #default="scope">
            <el-tag size="small " v-if="scope.row.quanxian == 1">全部</el-tag>
            <el-tag size="small " v-else-if="scope.row.quanxian == 2"
              >直播</el-tag
            >
            <el-tag size="small " v-else-if="scope.row.quanxian == 3"
              >医知医汇</el-tag
            >
            <el-tag size="small " v-else-if="scope.row.quanxian == 4"
              >超声e站</el-tag
            >
            <el-tag size="small " v-else-if="scope.row.quanxian == 5"
              >医知医汇.net</el-tag
            >
            <el-tag size="small " v-else>{{ scope.row.quanxian }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button type="text" size="small" @click="addClick(scope.row)"
              >编辑</el-button
            >
            <el-button type="text" size="small" @click="delect(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div>
      <el-dialog
        v-model="dialogVisible"
        title="权限"
        width="40%"
        :before-close="handleClose"
      >
        <edmit @childFn="parentFn" :edmitlist="edmitlist" :key="coms"></edmit>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import qs from 'qs'
import edmit from '../components/edmit/index.vue'
import { getusersbypage, updateuser } from '../../../api/index'
export default {
  data () {
    return {
      tableData: [],
      dialogVisible: false,
      edmitlist: '',
      coms: ''
    }
  },
  methods: {
    addClick (e) {
      console.log(e)
      this.dialogVisible = true
      this.edmitlist = e
      this.coms = new Date().getTime()
    },
    // 管理
    julistction () {
      this.dialogVisible = true
      this.edmitlist = ''
      this.coms = new Date().getTime()
    },
    // 页面加载获取用户
    contentcrea () {
      let app = qs.stringify({
        numperpage: 20,//默认20
        pagenumber: 0,
      })
      getusersbypage(app).then(res => {
        console.log(res)
        this.tableData = res.userrecords
      })
    },
    // 删除
    delect (e) {
      console.log(e)
      let app = qs.stringify({
        userid: e.userid,
        password: e.password,
        cridential: 3,
        quanxian: 0,
        username: e.username
      })
      updateuser(app).then(res => {
        console.log(res)
        if (res.code === 9999) {
          this.contentcrea()
        }
      })
    },
    parentFn (e) {
      console.log(e)
      if (e == 1) {
        this.dialogVisible = false,
          this.contentcrea()
      }
    }
  },
  created () {
    this.contentcrea()
  },
  components: {
    edmit
  }
}
</script>
<style scoped>
.nav .jurisd {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}
</style>
