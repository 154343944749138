<template>
  <div>
    <!-- 整体 -->
    <div class="title">内容模块</div>
    <div class="box">
      <!-- 内容展示 -->
      <div class="Exhibition">
        <!-- 顶部图片 -->
        <div class="image">
          <img
            src="https://tse4-mm.cn.bing.net/th/id/OIP-C.o0F_rhta_I3TucWiBwZe5gHaE8?pid=ImgDet&rs=1"
            alt=""
          />
        </div>
        <!-- 内容展示横框 -->
        <div>
          <el-tabs
            type="card"
            v-model="editableTabsValue"
            @tab-click="handleClick"
          >
            <el-tab-pane
              v-for="item in supn"
              :key="item.id"
              :label="item.title"
            >
              <div class="tablist">
                <div class="apptablist" @click="tableft(item.id)">向左</div>
                <div class="apptablist" @click="tabright(item.id)">向右</div>
                <div class="apptablist" @click="handleTabsEdit(item.id)">
                  删除
                </div>
                <div @click="editer" v-if="editerapp == false">更改</div>
                <div @click="editer" v-else-if="editerapp == true">确定</div>
              </div>
              <!-- 更改 -->
              <div v-if="editerapp">
                <input type="text" v-model="item.title" />
              </div>
              <!-- 图文 -->
              <div
                class="concettitle"
                v-html="item.cons"
                v-if="item.list == 1"
              ></div>
              <!-- 图文2 -->
              <div
                class="concettitle"
                v-html="item.cons"
                v-if="item.list == 6"
              ></div>
              <!-- 互动 -->
              <div class="concettitle" v-if="item.list == 2">
                <!-- {{ item.cons }} -->
                <img
                  style="width: 100%; height: 100%"
                  :src="item.cons"
                  alt=""
                />
              </div>
              <!-- 预约 -->
              <div class="concettitle" v-if="item.list == 3">
                {{ item.cons }}
              </div>
              <!-- 意见 -->
              <div class="concettitle" v-if="item.list == 4">
                <a :href="item.cons">{{ item.title }}</a>
              </div>
              <!-- 外部链接 -->
              <div class="concettitle" v-if="item.list == 5">
                <div>
                  <a :href="item.cons" target="my-iframe">{{ item.title }}</a>
                </div>
                <iframe
                  name="my-iframe"
                  id="my-iframe"
                  frameborder="0"
                  width="100%"
                  height="800"
                  scrolling="no"
                ></iframe>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <!-- 添加的内容 -->
      <div class="content">
        <div class="title">制作内容</div>
        <div>
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <!-- 互动 -->
            <el-tab-pane label="互动" name="2">
              <!-- 互动添加 -->
              <div class="interactionimage">
                <img src="../../images/7.jpg" alt="" />
              </div>
              <div class="interactionbottom">
                <el-button type="primary" @click="addtap">添加</el-button>
              </div>
            </el-tab-pane>
            <!-- 图文编辑器 -->
            <el-tab-pane label="图文编辑器" name="1">
              <quill @childFn="parentFn" :quil="quil"></quill>
            </el-tab-pane>
            <!-- 课程预约 -->
            <el-tab-pane label="课程预约" name="3">
              <div>这是预约内容</div>
              <el-button type="primary" @click="appyuyuetab">添加</el-button>
            </el-tab-pane>
            <!-- 意见反馈 -->
            <el-tab-pane label="外部链接" name="4">
              <div>请输入链接地址</div>
              <div>
                <input type="text" v-model="yijian.cons" />
              </div>
              <div class="interactionbottom">
                <el-button type="primary" @click="yiaddtab">添加</el-button>
              </div></el-tab-pane
            >
            <!-- 外部链接 -->
            <el-tab-pane label="内部链接" name="5">
              <div>请输入链接地址</div>
              <div>
                <input type="text" v-model="waibu.cons" />
              </div>
              <el-button type="primary" @click="addhearf">添加</el-button>
            </el-tab-pane>
            <!-- 图文编辑器2 -->
            <el-tab-pane label="图文编辑器2" name="6">
              <instar @childFn="instarapp" :instlistadd="instlistadd"></instar>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <!-- 结束提交下一步 -->
    <div class="interactionbottom">
      <el-button type="success" @click="addwhole">提交菜单</el-button>
    </div>
  </div>
</template>
<style scoped>
/* 内容模块 */
.title {
  margin: 0 auto;
  color: #000;
  font-size: 20px;
  text-align: center;
  font-weight: 900;
}
/* 整体 */
.box {
  display: flex;
  width: 1000px;
  margin: 0 auto;
}
/* 展示 */
.Exhibition {
  border: 1px solid #ccc;
  flex: 3;
}
/* 顶部图片 */
.image {
  width: 100%;
  height: 200px;
  border-bottom: 1px solid #ccc;
}
.image img {
  width: 100%;
  height: 100%;
}
/* 展示内容 */
.concettitle {
  height: 300px;
  border: 1px solid #ccc;
}
/* 展示内容向左向右 */
.tablist {
  display: flex;
  border: 1px solid #ccc;
  /* height: 15px; */
}
.tablist div {
  text-align: center;
  color: #000;
  font-size: 14px;
  flex: 1;
  /* background: ; */
}
.tablist .apptablist {
  border-right: 1px solid #ccc;
}
/deep/ .el-tabs__nav-wrap.is-scrollable {
  padding: 0;
}
/* 添加内容 */
.content {
  height: 560px;
  flex: 6;
  border: 1px solid #000;
}
/* 互动添加内容 */
.interactionimage {
  height: 400px;
  width: 400px;
}
.interactionimage img {
  width: 100%;
  height: 100%;
}
/* 互动按钮 */
.interactionbottom {
  margin: 0 auto;
  height: 50px;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
}
.el-tabs--card > .el-tabs__header .el-tabs__item.is-closable:hover {
  pointer-events: none !important;
  /* background-color: red !important; */
}
/* /deep/.el-tabs__item:hover .el-icon-close { */
/* color: red !important; */
/* pointer-events: none;
   */
/* display: none; */
/* } */
</style>
<script>
import quill from '../quil/quil.vue'
import instar from '../instar/index.vue'
// import imagelist from '../../images/7.jpg'
// import yi from '../../images/8.jpg'
export default {
  data () {
    return {
      activeName: '2',
      editableTabsValue: '0',
      // 展示
      supn: [
      ],
      hudong: { id: 1, title: '互动', list: 2, cons: '//txcos-1306887409.cos.ap-beijing.myqcloud.com/1639471907697-90-%5Bobject%20File%5D' },
      tuwen: { id: 0, title: '图文', list: 1, cons: '' },
      yuyue: { id: 2, title: '预约', list: 3, cons: '预约内容' },
      yijian: { id: 3, title: '外部链接', list: 4, cons: '' },
      waibu: { id: 4, title: '内部链接', list: 5, cons: '' },
      tuwentow: { id: 5, title: '图文2', list: 6, cons: '' },
      // 图文传子
      quil: '',
      // 更改名字
      editerapp: false,
      // 图文编辑器2
      instlistadd: '',
    }
  },
  props: ["interaction"],
  methods: {
    // 图文列表添加
    parentFn (payload) {
      for (var j = 0; j < this.supn.length; j++) {
        if (this.supn[j].list == 1) {
          this.supn.splice(j, 1)
          // console.log('1')
        }
      }
      if (this.supn.length == 5) {
        alert('最多添加五个')
      } else {
        let pay = `${payload}`
        let app = { id: this.tuwen.id, title: '图文', list: 1, cons: pay }
        this.supn.push(app)
        for (var i = 0; i < this.supn.length; i++) {
          this.supn[i].id = i
        }
      }
    },
    // 图文2
    instarapp (e) {
      for (var j = 0; j < this.supn.length; j++) {
        if (this.supn[j].list == 6) {
          this.supn.splice(j, 1)
          // console.log('1')
        }
      }
      if (this.supn.length == 5) {
        alert('最多添加五个')
      } else {
        let pay = `${e}`
        let app = { id: this.tuwentow.id, title: '图文2', list: 6, cons: pay }
        this.supn.push(app)
        for (var i = 0; i < this.supn.length; i++) {
          this.supn[i].id = i
        }
      }
    },
    // 展示向左移动
    tableft (e) {
      console.log(e)
      if (e != 0) {
        let arr = this.supn
        arr.splice(e - 1, 1, ...arr.splice(e, 1, arr[e - 1]));
        for (var i = 0; i < arr.length; i++) {
          arr[i].id = i
        }
        e = e - 1 + ''
        this.editableTabsValue = e
        console.log(this.supn, e)
      } else {
        alert('已经位于第一个')
      }
    },
    // 展示向右移动
    tabright (e) {
      let app = this.supn.length
      console.log(e)
      let b = parseInt(e)
      console.log(this.supn)
      if (b == app - 1) {
        alert('已经位于末尾')
      } else {
        let arr = this.supn
        console.log(arr)
        arr.splice(b, 1, ...arr.splice(b + 1, 1, arr[b]));
        //arr.splice(e + 1, 1, ...arr.splice(e, 1, arr[e + 1]));
        //console.log(arr)
        for (var i = 0; i < arr.length; i++) {
          arr[i].id = i
          console.log(arr[i].id)
        }
        b = b + 1 + ''
        this.editableTabsValue = b
        console.log(this.editableTabsValue)
      }
    },
    // 内容添加互动
    addtap () {
      for (var j = 0; j < this.supn.length; j++) {
        if (this.supn[j].list == 2) {
          this.supn.splice(j, 1)
          // console.log('1')
        }
      }
      if (this.supn.length == 5) {
        alert('最多添加五个')
      } else {
        let app = { id: this.hudong.id, title: '互动', list: 2, cons: '//txcos-1306887409.cos.ap-beijing.myqcloud.com/1639471907697-90-%5Bobject%20File%5D' }
        this.supn.push(app)
        for (var i = 0; i < this.supn.length; i++) {
          this.supn[i].id = i
        }
      }
    },
    // 预约内容添加
    appyuyuetab () {
      for (var j = 0; j < this.supn.length; j++) {
        if (this.supn[j].list == 3) {
          this.supn.splice(j, 1)
          // console.log('1')
        }
      }
      if (this.supn.length == 5) {
        alert('最多添加五个')
      } else {
        let app = { id: this.yuyue.id, title: '预约', list: 3, cons: '预约内容' }
        // console.log(this.supn[0].title)

        this.supn.push(app)
        for (var i = 0; i < this.supn.length; i++) {
          this.supn[i].id = i
        }
      }

      console.log(this.supn)
    },
    // 外部链接输入
    addhearf () {
      if (this.waibu.cons == '') {
        alert('外部链接不可为空')
      } else {
        for (var j = 0; j < this.supn.length; j++) {
          if (this.supn[j].list == 5) {
            this.supn.splice(j, 1)
          }
        }
        if (this.supn.length == 5) {
          alert('最多添加五个')
        } else {
          let app = { id: this.waibu.id, title: this.waibu.title, list: 5, cons: this.waibu.cons }
          this.supn.push(app)
          for (var i = 0; i < this.supn.length; i++) {
            this.supn[i].id = i
          }
        }
      }
    },
    // 意见反馈
    yiaddtab () {
      for (var j = 0; j < this.supn.length; j++) {
        if (this.supn[j].list == 4) {
          this.supn.splice(j, 1)
          // console.log('1')
        }
      }
      if (this.supn.length == 5) {
        alert('最多添加五个')
      } else {
        let app = { id: this.yijian.id, title: this.yijian.title, list: 4, cons: this.yijian.cons }
        this.supn.push(app)
        for (var i = 0; i < this.supn.length; i++) {
          this.supn[i].id = i
        }
      }
      console.log(this.supn)
    },
    // 内容展示删除
    handleTabsEdit (e) {
      let j = e
      this.supn.splice(j, 1)
      for (var i = 0; i < this.supn.length; i++) {
        this.supn[i].id = i
      }
      console.log(this.supn)
    },
    // 菜单提交
    addwhole () {
      console.log(this.supn)
      if (this.supn == '') {
        alert('菜单不能为空')
      } else if (this.supn == []) {
        alert('菜单不能为空')
      } else {
        let app = this.supn
        this.$emit("childFn", app)
        this.supn = []
      }
    },
    // 接受父传值发送给子
    instar () {
      console.log(this.interaction)
      this.supn = this.interaction
      console.log(this.supn)
      for (var i = 0; i < this.supn.length; i++) {
        if (this.supn[i].cons == 1) {
          console.log(this.supn[i].cons)
          this.quil = this.supn[i].cons
          console.log(this.quil)
        } else if (this.supn[i].cons == 6) {
          console.log(this.supn[i].cons)
          this.instlistadd = this.supn[i].cons
          console.log(this.quil)
        }
      }
    },
    // 更名展示名字
    editer () {
      console.log('1')
      this.editerapp = !this.editerapp
    },
    // 同步
    clickapp (e) {
      console.log(e)
    }
  },
  components: {
    quill,
    instar
  },
  created () {
    // console.log()
    setTimeout(this.instar, 500);
  }
}
</script>
