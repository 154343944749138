<template>
  <div>
    <div>
      <el-form ref="formRef" label-width="120px">
        <el-form-item label="选择权限" v-if="quanxian">
          <!-- 选择权限 -->
          <el-select v-model="value" class="m-2" placeholder="选择权限">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="账号/直播id">
          <el-input
            v-model="input1"
            class="w-50 m-2"
            size="large"
            placeholder="请输入"
            :disabled="listlive"
            :suffix-icon="Search"
          />
          <div>
            直播id：请写入819202100-819999999之间！账号：1或者2开头，8位
          </div>
        </el-form-item>
        <el-form-item label="用户名">
          <el-input
            v-model="input3"
            class="w-50 m-2"
            size="large"
            placeholder="请输入"
            :suffix-icon="Search"
        /></el-form-item>
        <el-form-item label="密码">
          <el-input
            v-model="input2"
            class="w-50 m-2"
            size="large"
            placeholder="请输入"
            :suffix-icon="Search"
        /></el-form-item>
      </el-form>
      <!-- 填写账号 -->
      
      <div class="submit">
        <el-button @click="submit" type="primary" size="small"
          >提交数据</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import qs from 'qs'
import { updateuser } from "../../../../api/index";
export default {

  data () {
    return {
      options: [
        {
          value: 2,
          label: '直播',
        },
        {
          value: 3,
          label: '医知医汇',
        },
        {
          value: 4,
          label: '超声e站',
        },
        {
          value: 5,
          label: '医知医汇net',
        }
      ],
      value: '',
      input1: "",
      input2: '',
      input3: '',
      // 是否可以输入
      listlive: true,
      quanxian: false
    }
  },
  props: ['edmitlist'],
  methods: {

    submit () {
      console.log('提交')
      if (this.value != '' && this.input1 != '' && this.input3 != '' && this.input2 != '') {
        let md = this.input1 + this.input2
        let password = window.btoa(md)
        console.log(password)
        let app = qs.stringify({
          userid: this.input1,
          password: password,
          cridential: this.input3,
          quanxian: this.value,
          username: this.input3
        })
        updateuser(app).then(res => {
          console.log(res)
          if (res.code == 9999) {
            this.$message({
              message: '修改成功',
              type: 'success'
            });
            this.$emit("childFn", '1')
          }
        })
      } else if (this.input1 != '' && this.input3 != '' && this.input2 != '') {
        let md = this.input1 + this.input2
        let password = window.btoa(md)
        // let
        let app = qs.stringify({
          userid: 0,
          password: password,
          cridential: this.input3,
          quanxian: this.input1,
          username: this.input3
        })
        updateuser(app).then(res => {
          console.log(res)
          if (res.code == 9999) {
            this.$message({
              message: '创建成功',
              type: 'success'
            });
            this.$emit("childFn", '1')
          }
        })
      } else {
        this.$message.error('必填不可为空');
      }
    }
  },
  created () {
   
    if (this.edmitlist == '') {
      this.value = ''
      this.input1 = ''
      this.input2 = ''
      this.input3 = ''
      this.jurisdictions = ''
      this.listlive = false
      this.quanxian = true
   
    } else {
      console.log(this.edmitlist)
      this.value = this.edmitlist.quanxian
      this.input1 = this.edmitlist.userid
      this.input2 = this.edmitlist.password
      this.input3 = this.edmitlist.username
      this.listlive = true
      this.quanxian = true
    }
    // 密码解密:
    // console.log(this.edmitlist.password)
    // const decodedData = window.atob(this.edmitlist.password);
    // console.log(decodedData)
  }
  
}
</script>
<style scoped>
.submit {
  text-align: center;
}
</style>
