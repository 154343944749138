<template>
  <div>
    <div class="text">直播id为：{{ baominglist.liveid }}</div>

    <div class="buttomlist">
      <el-button type="primary" @click="lastappbox" v-if="csslistabb == false"
        >查看报名表</el-button
      >
      <el-button
        type="primary"
        @click="lastappbox"
        v-else-if="csslistabb == true"
        >查看报名详情</el-button
      >
    </div>
    <div v-if="csslistabb == true">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="id" label="序号" width="180"> </el-table-column>
        <el-table-column prop="title" label="姓名" width="180">
        </el-table-column>
        <el-table-column label="地址">
          <template v-slot="scope">
            <el-button
              type="text"
              v-if="boxbaoming == scope.row.id"
              @click="livedeletbaoming(scope.row)"
              >取消报名表</el-button
            >
            <el-button v-else type="text" @click="aleatbaoming(scope.row)"
              >选择</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-else-if="csslistabb == false" class="download">
      <!-- 点击下载 -->
      <el-button type="primary" @click="downloadbao">下载详细数据</el-button>
      <!-- 报名表的详情 -->
      <div>
        <div class="list lisr1">
          <div class="box" v-for="item in titles" :key="item.id">
            {{ item }}
          </div>
          <!-- <div v-for="items in users" :key="items.id">{{ items }}</div> -->
        </div>
        <div class="list" v-for="items in users" :key="items.id">
          <!-- <div v-for="item in titles" :key="item.id">{{ item }}</div> -->
          <div class="box" v-for="itemlist in items" :key="itemlist.id">
            {{ itemlist }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script> 
import qs from 'qs';
import { baoming, baominglive, detachbaomingbiao, baomingbiaoreport, } from '../../../api/index'
export default {
  data () {
    return {
      tableData: [
      ],
      liveid: '',
      boxbaoming: '',
      // 查看报名表或者报名的详情
      csslistabb: true,
      // 报名表的详情
      titles: '',
      users: '',
    }
  },
  props: ['baominglist'],
  methods: {
    // 父组件的传值接受、获取报名表
    list () {
      this.boxbaoming = this.baominglist.livebaomingapp
      console.log(this.baominglist)
      this.liveid = this.baominglist.liveid
      baoming().then(res => {
        console.log(res)
        this.tableData = res.baomingbiaolist
      })
    },
    // 点击选择
    aleatbaoming (e) {
      this.$confirm('此操作将直播与报名表绑定, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log(this.liveid, e.id)
        let app = qs.stringify({
          baomingbiaoid: e.id,
          video_id: this.liveid
        })
        baominglive(app).then(res => {
          console.log(res)
          if (res.code === 9999) {
            this.$message({
              type: 'success',
              message: '绑定成功!'
            });
            this.boxbaoming = e.id
            this.$emit('childFn', '1')
          } else {
            alert('失败')
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消绑定'
        });
      });
      console.log(e.id)
    },
    // 取消报名表
    livedeletbaoming () {
      this.$confirm('此操作将取消直播与报名表绑定, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let app = qs.stringify({
          video_id: this.liveid
        })
        detachbaomingbiao(app).then(res => {
          console.log(res)
          if (res.code === 9999) {
            this.$message({
              type: 'success',
              message: '取消成功!'
            });
            this.boxbaoming = 0
            this.$emit('childFn', '1')
          } else {
            alert('失败')
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消解除绑定'
        });
      });
    },
    lastappbox () {
      this.csslistabb = !this.csslistabb
      if (this.csslistabb == false) {
        let app = qs.stringify({
          baomingbiaoid: this.boxbaoming,
          video_id: this.liveid
        })
        baomingbiaoreport(app).then(res => {
          console.log(res)
          this.titles = res.titles
          this.users = res.users
        })
      }
    },
    // 下载报名表详情
    downloadbao () {
      // console.log('xiazai')
      let baoid = this.boxbaoming
      let id = this.liveid
      let a = `https://tx4sdev.tongxianglive.cn/tx4s/getbaomingbiaoreportexcel/baomingbiaoid/${baoid}/video_id/${id}`
      const link = document.createElement('a');
      link.href = a
      // link.download = `${this.liveid}-${this.boxbaoming}`;
      link.click()
      //   link.download = `${this.liveid}直播报名表${this.boxbaoming}`;
      // baomingbiaoreportexcel(baoid, id).then(res => {
      //   console.log(res)
      //   const link = document.createElement('a');
      //   let blob = new Blob([res], { type: 'application/vnd.ms-excel;charset=UTF-8' });
      //   link.style.display = 'none';
      //   //设置连接
      //   link.href = URL.createObjectURL(blob);
      //   link.download = `${this.liveid}直播报名表${this.boxbaoming}`;
      //   document.body.appendChild(link);
      //   // link.href = window.URL.createObjectURL(blob)
      //   //模拟点击事件
      //   link.click();
      // })
      //  fileDownload(response,'dsdasd.xls');
    }
  },
  created () {
    this.list()
  },
}
</script>
<style scoped>
.text {
  text-align: center;
  font-size: 20px;
  font-size: 900;
  color: #000;
  padding: 0 0 20px 0;
}
.buttomlist {
  text-align: right;
  padding: 0 0 20px 0;
}
/* 报名表的详情 */
.list {
  width: 80%;
  margin: 0 auto;
  display: flex;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  text-align: center;
}
.lisr1 {
  height: 50px;
  margin-top: 2px;
}
.box {
  width: 180px;
  height: 80px;
  /* line-height: 80px; */
  /* overflow: scroll; */
  font-size: 14px;
  word-break: break-all;
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
}
.download {
  text-align: center;
}
</style>
